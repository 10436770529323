import { environment } from './../environments/environment';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private platform: Platform, private router: Router, private gtmService: GoogleTagManagerService) {
    this.initializeApp();
    // Injects meta tag into index.html to prevent indexation of dev and beta environments
    // https://developers.google.com/search/docs/crawling-indexing/block-indexing
    if (environment.environmentName !== 'prod') {
      let temp = document.createElement('meta');
      temp.innerHTML = '<meta name="robots" content="noindex, nofollow" />';
      const head = document.head;
      while (temp.firstChild) {
          head.appendChild(temp.firstChild);
      }
    }
    // <!-- Chat Movidesk -->
    // <script type="text/javascript">var mdChatClient="CBB26BB251BD4C7D8BCA7AB9A07EF9BE";</script>
    // <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js"></script>
    // <!-- Chat do Movidesk fim -->
    
    // if (environment.environmentName === 'TL') {
    //   let temp2 = document.createElement('meta');
    //   temp2.innerHTML = `<script type="text/javascript">var mdChatClient="CBB26BB251BD4C7D8BCA7AB9A07EF9BE";</script>`;
    //   const head = document.head;
    //   while (temp2.firstChild) {
    //       head.appendChild(temp2.firstChild);
    //   }
    //   temp2.innerHTML = '<script src="https://chat.movidesk.com/Scripts/chat-widget.min.js"></script>';
    //   while (temp2.firstChild) {
    //       head.appendChild(temp2.firstChild);
    //   }
    // }
  }

  ngOnInit() {
    if (environment.environmentName !== 'dev') {
      this.router.navigate(['']);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.platform.is('capacitor')){
        FirebaseAnalytics.initializeFirebase(environment.firebaseConfig).then(() =>{
          FirebaseAnalytics.setCollectionEnabled({
            enabled: true,
          });
          FirebaseAnalytics.logEvent({
            name: 'init',
            params: {

            },
          });
        });
      }
    });

    this.gtmService.addGtmToDom();
  }

  // Track an event:
  trackEvent(val) {
    // Label and Value are optional, Value is numeric
    //this.ga.trackEvent('Category', 'Action', 'Label', val)
  }
}

