import { environment } from 'src/environments/environment';
import { NotificationService } from './../notifications/notification.service';
import { UserService } from './../user/user.service';
import { LanguageService } from 'src/app/language/language.service';
import { ColibriAPIService, IRPYSensor } from './colibriAPI.service';
import { Injectable } from '@angular/core';
import { IOperationMode, IColibriConfig, IParam, IGestureAction, ISerial } from './IColibriConfig';
import { ColibriApiOperationMode, ColibriApiParamID, ColibriApiAction, ColibriApiSensorDataType } from './colibriApiValues.enum';
import { NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class ColibriHALService {

  readColibriStatePeriodMS = 5000;
  configSessionStarted = false;

  nextConfig: IColibriConfig;
  currentConfig: IColibriConfig;
  previousConfig: IColibriConfig;
  colibriID: string; // MAC Address
  colibriSerial: string; // Serial String
  colibriColor: string; // Color char
  readInterval;
  debugSession = true;

  sessionInitLogSent = false;

  operationModes: IOperationMode[] = [
    {
      id: ColibriApiOperationMode.standard,
      name: this.lang.words.config.operationMode.modes.standard,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.xyExclusive,
      name: this.lang.words.config.operationMode.modes.standardWoDiagonal,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.hgSwitch,
      name: this.lang.words.config.operationMode.modes.headTilt,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.singleAxisX,
      name: this.lang.words.config.operationMode.modes.leftRightOnly,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.singleAxisY,
      name: this.lang.words.config.operationMode.modes.upDownOnly,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.ablinx,
      name: this.lang.words.config.operationMode.modes.clickOnly,
      description: ' ',
    },
    {
      id: ColibriApiOperationMode.disabled,
      name: this.lang.words.config.operationMode.modes.deactivated,
      description: ' ',
    }
  ];

  // Atenção: editar a ordem dos elementos desse vetor requer atualização correspondente do config-gestures.page.html versão TiX Letramento
  instantActions: IGestureAction[] = [
    {
      id: ColibriApiAction.enable,
      name: this.lang.words.config.gesture.gestureActions.enable,
      description: ' ',
    },
    {
      id: ColibriApiAction.disable,
      name: this.lang.words.config.gesture.gestureActions.disable,
      description: ' ',
    },
    {
      id: ColibriApiAction.clickRight,
      name: this.lang.words.config.gesture.gestureActions.clickRight,
      description: ' ',
    },
    {
      id: ColibriApiAction.dwellClickToggle,
      name: this.lang.words.config.gesture.gestureActions.dwellClickToggle,
      description: 'Liga e desliga o clique mantendo o cursor na tela',
    },
    {
      id: ColibriApiAction.holdClickNext,
      name: this.lang.words.config.gesture.gestureActions.holdClickNext,
      description: 'Segura o próximo clique no modo de clique automático',
    },
    {
      id: ColibriApiAction.scrollByNodding,
      name: this.lang.words.config.gesture.gestureActions.freeScroll,
      description: ' ',
    },
    {
      id: ColibriApiAction.none,
      name: this.lang.words.config.gesture.gestureActions.none,
      description: ' ',
    }
  ];
  // Atenção: editar a ordem dos elementos desse vetor requer atualização correspondente do config-gestures.page.html versão TiX Letramento
  holdableActions: IGestureAction[] = [
    {
      id: ColibriApiAction.enable,
      name: this.lang.words.config.gesture.gestureActions.enable,
      description: ' ',
    },
    {
      id: ColibriApiAction.disable,
      name: this.lang.words.config.gesture.gestureActions.disable,
      description: ' ',
    },
    {
      id: ColibriApiAction.clickRight,
      name: this.lang.words.config.gesture.gestureActions.clickRight,
      description: ' ',
    },
    {
      id: ColibriApiAction.dwellClickToggle,
      name: this.lang.words.config.gesture.gestureActions.dwellClickToggle,
      description: 'Liga e desliga o clique mantendo o cursor na tela',
    },
    {
      id: ColibriApiAction.holdClickNext,
      name: this.lang.words.config.gesture.gestureActions.holdClickNext,
      description: 'Segura o próximo clique no modo de clique automático',
    },
    {
      id: ColibriApiAction.scrollUp,
      name: this.lang.words.config.gesture.gestureActions.scrollUp,
      description: ' ',
    },
    {
      id: ColibriApiAction.scrollDown,
      name: this.lang.words.config.gesture.gestureActions.scrollDown,
      description: ' ',
    },
    {
      id: ColibriApiAction.none,
      name: this.lang.words.config.gesture.gestureActions.none,
      description: ' ',
    }
  ];

  paramUpdatedBS: BehaviorSubject <IParam> = new BehaviorSubject <IParam>({id: 0, name: '', value: 0});
  statusUpdatedBS: BehaviorSubject <string> = new BehaviorSubject <string>('disconnected');
  configUpdatedBS: BehaviorSubject <any> = new BehaviorSubject <any>(undefined);
  colibriRPYDataBS: BehaviorSubject <IRPYSensor>;
  colibriSerialBS: BehaviorSubject <ISerial>;

  private keepAliveInterval;

  // State for readColibriState function;
  private readColibriStateValues: number[];
  private readColibriStateInterval;

  private paramUpdatedSubscription;
  private connectionStateSubscription;
  private macSubscription;
  private serialSubscription;

  constructor(private api: ColibriAPIService,
    private lang: LanguageService,
    private plt: Platform,
    private notification: NotificationService,
    private log: LogService,
    private userService: UserService,
    private navCtrl: NavController) {
      console.log('ColibriHALService init');

      this.currentConfig = this.clearConfigData();
      this.nextConfig = JSON.parse(JSON.stringify(this.currentConfig));

      this.paramUpdatedSubscription = this.api.paramUpdated.subscribe((param: IParam) => this.paramUpdated(param));
      this.macSubscription = this.api.colibriMAC.subscribe((mac) => this.macUpdated(mac));
      this.serialSubscription = this.api.colibriSerial.subscribe((serial: ISerial) => this.serialUpdated(serial));
      this.statusUpdatedBS = this.api.connectionState;
      this.colibriRPYDataBS = this.api.colibriRPYData;

      this.colibriSerialBS = this.api.colibriSerial;
  }

  ngOnDestroy() {
    console.log('ColibriHALService', 'ngOnDestroy', this.paramUpdatedSubscription.unsubscribe() );
    this.paramUpdatedSubscription.unsubscribe();
    this.connectionStateSubscription.unsubscribe();
    this.macSubscription.unsubscribe();
    clearInterval(this.readInterval);
    this.disconnect();
  }

  // Connect to Colibri
  async connect() {
    this.api.connect();
    if (!this.keepAliveInterval && !this.plt.is('capacitor')) {
      // this.keepAliveInterval = setInterval(() => this.api.keepAlive(31000), 6000);
    } // TODO: COL-177
  }

  // Disconnect to Colibri
  disconnect() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = undefined;
      this.api.keepAlive(0);
    }
    return this.api.disconnect();
  }

  configIsEqual(conf1: IColibriConfig, conf2: IColibriConfig) {
    if (JSON.stringify(conf1) === JSON.stringify(conf2)) {
      if(this.debugSession) {console.log('configIsEqual');}
      return true;
    } else {
      if(this.debugSession) {console.log('configIsNOTEqual');}
      return false;
    }
  }

  async synchronizeErrorHandler(paramID: ColibriApiParamID) {
    if(this.debugSession) {console.log('readParam for sync Error');}
    await this.api.readParam(paramID,7000).then(async () => { //A leitura do param deu certo
      if (this.configIsEqual(this.nextConfig,this.currentConfig)) {
        if (this.debugSession) {console.log(paramID,': was saved');}
      } else {
        await this.trySyncAgain();
      }
    }, async () => { //A releitura do param deu errado de novo
      await this.trySyncAgain();
    });
  }

  async trySyncAgain() {
    if(this.debugSession) {console.log('[HAL] Sync TRY AGAIN');}
    this.notification.alertControl('errSync').then( res => {
      if(res === 'confirm') {
        this.synchronize();
      }
    }, err => {
      // Desfaz a mudança que não sincronizada
      if (err === 'cancel') {
        this.nextConfig = JSON.parse(JSON.stringify(this.currentConfig));
        this.navCtrl.navigateForward('/home');
      }
    });
  }

  // Synchronize any changes, atualiza o current
  async synchronize() {
    if (this.configIsEqual(this.nextConfig,this.currentConfig)){ return; }

    if (!environment.production) {
      console.groupCollapsed('Colibri State');
      console.log('currentConfig' ,this.currentConfig);
      console.log('nextConfig', this.nextConfig);
      console.groupEnd();
    }

    try {

      if (this.nextConfig.operationMode.id !== this.currentConfig.operationMode.id) {
        switch (this.nextConfig.operationMode.id) {
          // No modo ablinx é necessário bloquear o clique automático
          case ColibriApiOperationMode.ablinx:
            this.nextConfig.dwellClick = false;
            break;
        }
        await this.api.writeParamUint(ColibriApiParamID.operationMode, this.nextConfig.operationMode.id).then(() => {
          this.currentConfig.operationMode = this.nextConfig.operationMode;
        }, async () => {
          if(this.debugSession) {console.log('ERR: writeParamUint operationMode');}
          await this.synchronizeErrorHandler(ColibriApiParamID.operationMode);
        });
      }

      if ((this.nextConfig.buzzerLevel !== this.currentConfig.buzzerLevel) ||
      (this.nextConfig.buzzerLevelClick !== this.currentConfig.buzzerLevelClick)) {
        // Determina um nível mínimo para o som do colibri
        // if (this.nextConfig.buzzerLevel < 3) {
        //   this.nextConfig.buzzerLevel = 3;
        // }
        await this.api.writeParamUint(ColibriApiParamID.buzzerAnimationLvl, this.nextConfig.buzzerLevel).then(() => {
          this.currentConfig.buzzerLevel = this.nextConfig.buzzerLevel;
        }, async () => {
          if(this.debugSession) {console.log('ERR: writeParamUint buzzerLevel');}
          await this.synchronizeErrorHandler(ColibriApiParamID.buzzerAnimationLvl);
        });
        await this.api.writeParamUint(ColibriApiParamID.buzzerClickLvl, this.nextConfig.buzzerLevel).then(() => {
          this.currentConfig.buzzerLevelClick = this.nextConfig.buzzerLevel;
          this.nextConfig.buzzerLevelClick = this.nextConfig.buzzerLevel;
        }, async () => {
          if(this.debugSession) {console.log('ERR: writeParamUint buzzerClickLvl');}
          await this.synchronizeErrorHandler(ColibriApiParamID.buzzerClickLvl);
        });
      }

      if ((this.nextConfig.xSpeed !== this.currentConfig.xSpeed) ||
      (this.nextConfig.xSpeedProgressive !== this.currentConfig.xSpeedProgressive)) {
        const apiSpeedX = this.nextConfig.xSpeed * (this.nextConfig.xSpeedProgressive ? -1 : 1);
        await this.api.writeParamInt(ColibriApiParamID.xSpeed, apiSpeedX).then(() => {
          this.currentConfig.xSpeed = this.nextConfig.xSpeed;
          this.currentConfig.xSpeedProgressive = this.nextConfig.xSpeedProgressive;
        }, async () => {
          if(this.debugSession) {console.log('ERR: writeParamInt xSpeed');}
          await this.synchronizeErrorHandler(ColibriApiParamID.xSpeed);
        });
      }

      if ((this.nextConfig.ySpeed !== this.currentConfig.ySpeed)||
      (this.nextConfig.ySpeedProgressive !== this.currentConfig.ySpeedProgressive)) {
        const apiSpeedY = this.nextConfig.ySpeed * (this.nextConfig.ySpeedProgressive ? -1 : 1);
        await this.api.writeParamInt(ColibriApiParamID.ySpeed, apiSpeedY).then(() => {
          this.currentConfig.ySpeed = this.nextConfig.ySpeed;
          this.currentConfig.ySpeedProgressive = this.nextConfig.xSpeedProgressive;
        }, async () => {
          if(this.debugSession) {console.log('ERR: writeParamInt ySpeed');}
          await this.synchronizeErrorHandler(ColibriApiParamID.ySpeed);
        });
      }

      if (this.nextConfig.scrollSpeed !== this.currentConfig.scrollSpeed) {
        const apiSpeed = this.nextConfig.scrollSpeed * (this.nextConfig.scrollSpeedProgressive ? -1 : 1);
        await this.api.writeParamInt(ColibriApiParamID.scrollSpeed, apiSpeed).then(() => {
          this.currentConfig.scrollSpeed = this.nextConfig.scrollSpeed;
          this.currentConfig.scrollSpeedProgressive = this.nextConfig.scrollSpeedProgressive;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamInt scrollSpeed');}
          this.synchronizeErrorHandler(ColibriApiParamID.scrollSpeed);
        });
      }

      if (this.nextConfig.rollAngle !== this.currentConfig.rollAngle) {
        const apiAngle = 40 - this.nextConfig.rollAngle;
        await this.api.writeParamUint(ColibriApiParamID.rollAngle, apiAngle).then(() => {
          this.currentConfig.rollAngle = this.nextConfig.rollAngle;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint rollAngle');}
          this.synchronizeErrorHandler(ColibriApiParamID.rollAngle);
        });
        await this.api.writeParamUint(ColibriApiParamID.pitchAngle, apiAngle).then(() => {
          this.currentConfig.pitchAngle = this.nextConfig.rollAngle;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint pitchAngle');}
          this.synchronizeErrorHandler(ColibriApiParamID.pitchAngle);
        });
      }

      if ((this.nextConfig.rollLeftActionA.id !== this.currentConfig.rollLeftActionA.id) ||
      (this.nextConfig.rollLeftActionB.id !== this.currentConfig.rollLeftActionB.id) ||
      (this.nextConfig.rollRightActionA.id !== this.currentConfig.rollRightActionA.id) ||
      (this.nextConfig.rollRightActionB.id !== this.currentConfig.rollRightActionB.id)) {
        await this.api.writeParam4bytes(ColibriApiParamID.actionRoll, this.nextConfig.rollRightActionA.id,
        this.nextConfig.rollRightActionB.id, this.nextConfig.rollLeftActionA.id, this.nextConfig.rollLeftActionB.id).then(() => {
            this.currentConfig.rollLeftActionA = this.nextConfig.rollLeftActionA;
            this.currentConfig.rollLeftActionB = this.nextConfig.rollLeftActionB;
            this.currentConfig.rollRightActionA = this.nextConfig.rollRightActionA;
            this.currentConfig.rollRightActionB = this.nextConfig.rollRightActionB;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParam4bytes actionRoll');}
          this.synchronizeErrorHandler(ColibriApiParamID.actionRoll);
        });
      }

      if ((this.nextConfig.quickRollLeftActionA.id !== this.currentConfig.quickRollLeftActionA.id) ||
      (this.nextConfig.quickRollLeftActionB.id !== this.currentConfig.quickRollLeftActionB.id) ||
      (this.nextConfig.quickRollRightActionA.id !== this.currentConfig.quickRollRightActionA.id) ||
      (this.nextConfig.quickRollRightActionB.id !== this.currentConfig.quickRollRightActionB.id)) {
        await this.api.writeParam4bytes(ColibriApiParamID.actionQuickRoll, this.nextConfig.quickRollRightActionA.id,
        this.nextConfig.quickRollRightActionB.id, this.nextConfig.quickRollLeftActionA.id,
        this.nextConfig.quickRollLeftActionB.id).then(() => {
            this.currentConfig.quickRollLeftActionA = this.nextConfig.quickRollLeftActionA;
            this.currentConfig.quickRollLeftActionB = this.nextConfig.quickRollLeftActionB;
            this.currentConfig.quickRollRightActionA = this.nextConfig.quickRollRightActionA;
            this.currentConfig.quickRollRightActionB = this.nextConfig.quickRollRightActionB;
            console.log(this.currentConfig.quickRollLeftActionA,this.nextConfig.quickRollLeftActionA);
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParam4bytes actionQuickRoll');}
          this.synchronizeErrorHandler(ColibriApiParamID.actionQuickRoll);
        });
      }

      if ((this.nextConfig.xNoddingActionA.id !== this.currentConfig.xNoddingActionA.id) ||
      (this.nextConfig.xNoddingActionB.id !== this.currentConfig.xNoddingActionB.id) ||
      (this.nextConfig.yNoddingActionA.id !== this.currentConfig.yNoddingActionA.id) ||
      (this.nextConfig.yNoddingActionB.id !== this.currentConfig.yNoddingActionB.id)) {
        await this.api.writeParam4bytes(ColibriApiParamID.actionNodding, this.nextConfig.xNoddingActionA.id,
          this.nextConfig.xNoddingActionB.id, this.nextConfig.yNoddingActionA.id, this.nextConfig.yNoddingActionB.id).then(() => {
            this.currentConfig.xNoddingActionA = this.nextConfig.xNoddingActionA;
            this.currentConfig.xNoddingActionB = this.nextConfig.xNoddingActionB;
            this.currentConfig.yNoddingActionA = this.nextConfig.yNoddingActionA;
            this.currentConfig.yNoddingActionB = this.nextConfig.yNoddingActionB;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParam4bytes actionNodding');}
          this.synchronizeErrorHandler(ColibriApiParamID.actionNodding);
        });
      }

      if (this.nextConfig.xZeroZone !== this.currentConfig.xZeroZone){
        await this.api.writeParamUint(ColibriApiParamID.xZeroZone, this.nextConfig.xZeroZone).then(() => {
          this.currentConfig.xZeroZone = this.nextConfig.xZeroZone;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint xZeroZone');}
          this.synchronizeErrorHandler(ColibriApiParamID.xZeroZone);
        });
      }

      if (this.nextConfig.yZeroZone !== this.currentConfig.yZeroZone){
        await this.api.writeParamUint(ColibriApiParamID.yZeroZone, this.nextConfig.yZeroZone).then(() => {
          this.currentConfig.yZeroZone = this.nextConfig.yZeroZone;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint yZeroZone');}
          this.synchronizeErrorHandler(ColibriApiParamID.yZeroZone);
        });
      }

      if (this.nextConfig.clickSensitivity !== this.currentConfig.clickSensitivity) {
        await this.api.writeParamUint(ColibriApiParamID.clickSensitivity, this.nextConfig.clickSensitivity).then(() => {
          this.currentConfig.clickSensitivity = this.nextConfig.clickSensitivity;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint clickSensitivity');}
          this.synchronizeErrorHandler(ColibriApiParamID.clickSensitivity);
        });
      }

      if ((this.nextConfig.clickTime !== this.currentConfig.clickTime) ||
      (this.nextConfig.clickTimeFilter !== this.currentConfig.clickTimeFilter) ||
      (this.nextConfig.dwellClick !== this.currentConfig.dwellClick)) {

        // Interlock between dwellClick and clickTimeFilter
        if (this.nextConfig.dwellClick && (this.nextConfig.dwellClick !== this.currentConfig.dwellClick)) {
          this.nextConfig.clickTimeFilter = false;
        }
        else if (this.nextConfig.clickTimeFilter && (this.nextConfig.clickTimeFilter !== this.currentConfig.clickTimeFilter)) {
          this.nextConfig.dwellClick = false;
        }

        // Default time on flipping the switch
        if((!this.nextConfig.dwellClick) && (!this.nextConfig.clickTimeFilter)) {
          this.nextConfig.clickTime = 0;
        }
        else if ((this.nextConfig.clickTime === 0) && (this.nextConfig.dwellClick)) {
          this.nextConfig.clickTime = 1200;
        }
        else if ((this.nextConfig.clickTime === 0) && (this.nextConfig.clickTimeFilter)) {
          this.nextConfig.clickTime = 600;
        }
        const apiClickTime = this.nextConfig.clickTime / 100 * (this.nextConfig.dwellClick ? 1 : -1);
        console.log('apiClickTime', apiClickTime);
        await this.api.writeParamInt(ColibriApiParamID.clickTimeControl, apiClickTime).then(() => {
          this.currentConfig.clickTime = this.nextConfig.clickTime;
          this.currentConfig.clickTimeFilter = this.nextConfig.clickTimeFilter;
          this.currentConfig.dwellClick = this.nextConfig.dwellClick;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamInt clickTimeControl');}
          this.synchronizeErrorHandler(ColibriApiParamID.clickTimeControl);
        });
      }

      if ((this.nextConfig.movementTime !== this.currentConfig.movementTime) ||
      (this.nextConfig.movementTimeFilter !== this.currentConfig.movementTimeFilter) ||
      (this.nextConfig.stopDuringClick !== this.currentConfig.stopDuringClick)) {

        // Interlock between movementTimeFilter and movementBeforeClickFilter
        if (this.nextConfig.movementTimeFilter && (this.nextConfig.movementTimeFilter !== this.currentConfig.movementTimeFilter)) {
          this.nextConfig.stopDuringClick = false;
        }
        else if (this.nextConfig.stopDuringClick &&
          (this.nextConfig.stopDuringClick !== this.currentConfig.stopDuringClick)) {
          this.nextConfig.movementTimeFilter = false;
        }

        // Default time on flipping the switch
        if((!this.nextConfig.movementTimeFilter) && (!this.nextConfig.stopDuringClick)) {
          this.nextConfig.movementTime = 0;
        }
        else if ((this.nextConfig.movementTime === 0) && (this.nextConfig.movementTimeFilter)) {
          this.nextConfig.movementTime = 500;
        }
        else if ((this.nextConfig.movementTime === 0) && (this.nextConfig.stopDuringClick)) {
          this.nextConfig.movementTime = 500;
        }
        const apiMovementTime = this.nextConfig.movementTime / 100 * (this.nextConfig.stopDuringClick ? 1 : -1);
        console.log('apiClickTime', apiMovementTime);
        await this.api.writeParamInt(ColibriApiParamID.movementTimeControl, apiMovementTime).then(() => {
          this.currentConfig.movementTime = this.nextConfig.movementTime;
          this.currentConfig.movementTimeFilter = this.nextConfig.movementTimeFilter;
          this.currentConfig.stopDuringClick = this.nextConfig.stopDuringClick;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamInt movementTimeControl');}
          this.synchronizeErrorHandler(ColibriApiParamID.movementTimeControl);
        });
      }

      if (this.nextConfig.yesNoLevel !== this.currentConfig.yesNoLevel) {
        const apiValue = (5 - this.nextConfig.yesNoLevel);
        await this.api.writeParamUint(ColibriApiParamID.gestureIntensity, apiValue).then(() => {
          this.currentConfig.gestureIntensityLevel = this.nextConfig.gestureIntensityLevel;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint gestureIntensity');}
          this.synchronizeErrorHandler(ColibriApiParamID.gestureIntensity);
        });
        await this.api.writeParamUint(ColibriApiParamID.gestureSpeed, apiValue).then(() => {
          this.currentConfig.yesNoLevel = this.nextConfig.yesNoLevel;
          this.currentConfig.gestureSpeedLevel = this.nextConfig.gestureSpeedLevel;
        }, () => {
          if(this.debugSession) {console.log('ERR: writeParamUint gestureSpeed');}
          this.synchronizeErrorHandler(ColibriApiParamID.gestureSpeed);
        });
      }
    } catch (error) {
      console.log('error synchronize');
    }
    console.log('synchronize:', 'done');
  }

  // Commits changes to permanent memory
  commit() {
    return this.api.commitParam(ColibriApiParamID.allParams).then((x) => {
      this.log.logColibriEvent(
        this.colibriID,
        this.colibriSerial,
        this.colibriColor,
        this.previousConfig,
        this.nextConfig);
        return Promise.resolve(x);
    }, (err) => Promise.reject(err)).catch( async err => {
      console.log('ERR: commit');
      this.notification.alertControl('errCommit').then(() => {}, () => {});
    });
  }

  async identifyColibri(){
    let readResult;
    try {
      readResult = await this.api.readParam(ColibriApiParamID.apiVersion, 3000);

      if (this.currentConfig.apiVersion >= 13) {
        readResult = await this.api.getMacAddr(this.currentConfig.apiVersion);
      } else if (this.currentConfig.apiVersion >= 11) {
        const delay = ms => new Promise(r => setTimeout(r, ms));
        readResult = await this.api.getMacAddr(this.currentConfig.apiVersion);
        delay(600);
        readResult = await this.api.getMacAddr(this.currentConfig.apiVersion);
        delay(600);
        readResult = await this.api.getMacAddr(this.currentConfig.apiVersion);
      } else {
        readResult = await this.api.getMacAddr(this.currentConfig.apiVersion);
      }

      if (this.currentConfig.apiVersion >= 12) {
        readResult = await this.api.getSerial();
      }

      if (!environment.production) {console.log('identifyColibri OK');}
      return true;
    } catch (error) {
      console.log('identifyColibri unable to communicate with Colibri', readResult);
    }
    return false; // Failed
  }

  async readAll(timeout: number = 0) {

    await this.api.readParam(ColibriApiParamID.allParams, timeout);
    if(this.plt.is('capacitor')){
      setTimeout (() => {
        this.readColibriState();
      }, 200);
    }

    // if (this.currentConfig.apiVersion <= 13) {
    //   setTimeout (() => {
    //     this.readColibriState();
    //   }, 400);
    // }

    // if (this.currentConfig.apiVersion >= 11) {
    //   setTimeout (() => {
    //     this.api.sendAction(ColibriApiAction.preventSleep5m);
    //   }, 5000);
    // }
  }

  /**
   * Executa a leitura sequencial dos parâmetros do Colibri sem usar o coringa ColibriApiParamID.allParams
   *
   * @returns
   */
  async readColibriState() {

    // Só deixa iniciar se o ciclo de leitura anterior
    if (this.readColibriStateInterval !== undefined) { // Previous read not finished
      return;
    }

    // Monta um vetor com todos os possíveis IDs
    this.readColibriStateValues = [];
    for (const entry in ColibriApiParamID) {
        if (!isNaN(Number(entry))) {
          this.readColibriStateValues.push(parseInt(entry, 10));
      }
    }

    //console.log('readColibriState', JSON.stringify(this.readColibriStateValues));
    this.readColibriStateInterval = setInterval (() => {
      // Retira ID do vetor e tenta fazer a leitura sem aguardar confirmação
      const val = this.readColibriStateValues.pop();
      if (val === undefined) {
        clearInterval(this.readColibriStateInterval);
        this.readColibriStateInterval = undefined;
        //console.log('readColibriState','end', JSON.stringify(val));
      }
      else if (val !== ColibriApiParamID.allParams) { // Reached a value
        //console.log('readColibriState', JSON.stringify(val));
        this.api.readParam(val, 0);
      }
    }, 2 * 0.9 * this.readColibriStatePeriodMS / this.readColibriStateValues.length);
  }

  // Returns to factory configuration
  factoryReset() {
    if(this.debugSession) {console.log('factoryReset');}
    this.notification.loaderControl('ON','factoryReset').then((res) => {
      console.log('[Notification]', res);
    }, err => {});
    this.api.factoryResetParam(ColibriApiParamID.allParams,2500).then(() => {
      this.currentConfig = this.clearConfigData();
      // Leitura dos novos params do Colibri
      setTimeout(() => {
        this.readAll(3000).then(() => {
          this.notification.loaderControl('ON','successFactoryReset', 1000).then((res) => {
            console.log('[Notification]', res);
          }, err => {});
          // Aguarda resposta do Colibri chegar para atualizar a interface
          setTimeout(() => {
            this.nextConfig = JSON.parse(JSON.stringify(this.currentConfig));
          }, 2500);
        });
      }, 500);
    }, () => {
      if(this.debugSession) {console.log('ERR: factoryReset');}
      this.notification.loaderControl('ON','failFactoryReset', 3000).then((res) => {
        console.log('[Notification]', res);
      }, err => {});
    });
  }

  setSerial(serial, color) {
    this.api.setSerial(serial, color);
  }

  // Atualiza o mac
  macUpdated(mac: string) {
    const macTrimmed = mac.replace(/\0[\s\S]*$/g,''); // Remove caracteres nulos utf-8
    if(macTrimmed.length === 6 && this.currentConfig.apiVersion <= 10) {
      this.colibriID = 'XXXXXX' + macTrimmed; // XXXXXX wil be replaced in function
    }
    if(macTrimmed.length === 6) {
      this.colibriID = '000000' + macTrimmed;
    } else if (macTrimmed.length === 12) {
      this.colibriID = macTrimmed;
    } else if (macTrimmed.length === 17) {
      this.colibriID = macTrimmed.split(':').join('');
    }
    console.log('mac id', this.colibriID);
  }

  serialUpdated(serial: ISerial) {
    const serialTrimmed = serial.serial.replace(/\0[\s\S]*$/g,''); // Remove caracteres nulos utf-8
    this.colibriSerial = serialTrimmed;
    this.colibriColor = serial.color;
    console.log('serial', this.colibriSerial, 'color', this.colibriColor);
    if(serial.serialUpdated) {
      // TODO: Nesse caso queremos garantir que o Log seja registrado
      this.log.logColibriEvent(
        this.colibriID,
        this.colibriSerial,
        this.colibriColor).then(() => {
          this.notification.loaderControl('ON','successCommit', 1000).then((res) => {
            console.log('[Notification]', res);
          }, err => {});
        }, () => {

        }
        );
    }
  }

  rpySensorEnable(enable: boolean) {
    if (enable) {
      this.api.setRawSensorOutput(ColibriApiSensorDataType.rpyIrGestVolt);
    } else {
      this.api.setRawSensorOutput(ColibriApiSensorDataType.disabled);
    }
  }
  // Process parameter updates
  // Função chamada quando o colibri responde uma operação de leitura
  // Atualiza o currentConfig
  paramUpdated(param: IParam) {
    //console.log('paramUpdated', JSON.stringify(param));
    switch (param.id) {
      case ColibriApiParamID.apiVersion:
        this.currentConfig.apiVersion = param.value;
        if (this.nextConfig.apiVersion === undefined
          || this.nextConfig.apiVersion === 0) { this.nextConfig.apiVersion = this.currentConfig.apiVersion; }
        // if (this.currentConfig.apiVersion <= 10) {
        //   this.api.getMacAPI10();
        // }
        this.configUpdatedBS.next({
          apiVersion: this.currentConfig.apiVersion
        });
        break;
      case ColibriApiParamID.hwVersion:
        this.currentConfig.hardwareVersion = param.value;
        if (this.nextConfig.hardwareVersion === undefined) { this.nextConfig.hardwareVersion = this.currentConfig.hardwareVersion; }
        this.configUpdatedBS.next({
          hardwareVersion: this.currentConfig.hardwareVersion
        });
        break;

      case ColibriApiParamID.fwVersion:
        this.currentConfig.firmwareVersion = param.value;
        if (this.nextConfig.firmwareVersion === undefined) { this.nextConfig.firmwareVersion = this.currentConfig.firmwareVersion; }
        this.configUpdatedBS.next({
          firmwareVersion: this.currentConfig.firmwareVersion
        });
        break;

      case ColibriApiParamID.fwRev:
        this.currentConfig.firmwareRevision = param.value;
        if (this.nextConfig.firmwareRevision === undefined) { this.nextConfig.firmwareRevision = this.currentConfig.firmwareRevision; }
        this.configUpdatedBS.next({
          firmwareRevision: this.currentConfig.firmwareRevision
        });
        break;

      case ColibriApiParamID.operationMode:
        this.currentConfig.operationMode = this.operationModes.find(element => element.id === param.value);
        if (this.nextConfig.operationMode.id === undefined) { this.nextConfig.operationMode = this.currentConfig.operationMode; }
        this.configUpdatedBS.next({
          operationMode: this.currentConfig.operationMode
        });
        break;

      case ColibriApiParamID.buzzerClickLvl:
        this.currentConfig.buzzerLevelClick = param.value;
        if (this.nextConfig.buzzerLevelClick === undefined) { this.nextConfig.buzzerLevelClick = this.currentConfig.buzzerLevelClick; }
        this.configUpdatedBS.next({
          buzzerLevelClick: this.currentConfig.buzzerLevelClick
        });
        break;

      case ColibriApiParamID.buzzerAnimationLvl:
        this.currentConfig.buzzerLevel = param.value;
        if (this.nextConfig.buzzerLevel === undefined) { this.nextConfig.buzzerLevel = this.currentConfig.buzzerLevel; }
        this.configUpdatedBS.next({
          buzzerLevel: this.currentConfig.buzzerLevel
        });
        break;

      case ColibriApiParamID.xSpeed:
        this.currentConfig.xSpeed = Math.abs(param.value);
        this.currentConfig.xSpeedProgressive = (param.value < 0);
        if (this.nextConfig.xSpeed === undefined) {
          this.nextConfig.xSpeed = this.currentConfig.xSpeed;
          this.nextConfig.xSpeedProgressive = this.currentConfig.xSpeedProgressive;
        }
        this.configUpdatedBS.next({
          xSpeed:this.currentConfig.xSpeed,
          xSpeedProgressive:this.currentConfig.xSpeedProgressive,
        });
        break;

      case ColibriApiParamID.ySpeed:
        this.currentConfig.ySpeed = Math.abs(param.value);
        this.currentConfig.ySpeedProgressive = (param.value < 0);
        if (this.nextConfig.ySpeed === undefined) {
          this.nextConfig.ySpeed = this.currentConfig.ySpeed;
          this.nextConfig.ySpeedProgressive = this.currentConfig.ySpeedProgressive;
        }
        this.configUpdatedBS.next({
          ySpeed:this.currentConfig.ySpeed,
          ySpeedProgressive:this.currentConfig.ySpeedProgressive,
        });
        break;

      case ColibriApiParamID.scrollSpeed:
        this.currentConfig.scrollSpeed = Math.abs(param.value);
        this.currentConfig.scrollSpeedProgressive = (param.value < 0);
        if (this.nextConfig.scrollSpeed === undefined) {
          this.nextConfig.scrollSpeed = this.currentConfig.scrollSpeed;
          this.nextConfig.scrollSpeedProgressive = this.currentConfig.scrollSpeedProgressive;
        }
        this.configUpdatedBS.next({
          scrollSpeed:this.currentConfig.scrollSpeed,
          scrollSpeedProgressive:this.currentConfig.scrollSpeedProgressive,
        });
        break;

      case ColibriApiParamID.rollAngle:
        this.currentConfig.rollAngle = 40 - param.value;
        if (this.nextConfig.rollAngle === undefined) { this.nextConfig.rollAngle = this.currentConfig.rollAngle; }
        this.configUpdatedBS.next({
          rollAngle:this.currentConfig.rollAngle
        });
        break;

      case ColibriApiParamID.pitchAngle:
        this.currentConfig.pitchAngle = 40 - param.value;
        if (this.nextConfig.pitchAngle === undefined) { this.nextConfig.pitchAngle = this.currentConfig.pitchAngle; }
        this.configUpdatedBS.next({
          pitchAngle:this.currentConfig.pitchAngle
        });
        break;

      case ColibriApiParamID.actionRoll:
        //console.log(JSON.stringify(param.value));
        this.currentConfig.rollRightActionA = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.rollRightActionA));
        this.currentConfig.rollRightActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.rollRightActionB));
        this.currentConfig.rollLeftActionA = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.rollLeftActionA));
        this.currentConfig.rollLeftActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.rollLeftActionB));
        if (this.nextConfig.rollLeftActionA.id === undefined) {
          this.nextConfig.rollRightActionA = this.currentConfig.rollRightActionA;
          this.nextConfig.rollRightActionB = this.currentConfig.rollRightActionB;
          this.nextConfig.rollLeftActionA = this.currentConfig.rollLeftActionA;
          this.nextConfig.rollLeftActionB = this.currentConfig.rollLeftActionB;
        }
        this.configUpdatedBS.next({
          rollRightActionA:this.currentConfig.rollRightActionA,
          rollRightActionB:this.currentConfig.rollRightActionB,
          rollLeftActionA:this.currentConfig.rollLeftActionA,
          rollLeftActionB:this.currentConfig.rollLeftActionB
        });
        break;

      case ColibriApiParamID.actionQuickRoll:
        //console.log(JSON.stringify(param.value));
        if (this.currentConfig.firmwareRevision < 23) { // Adaptação para não mostrar gesto gravado no colibri em função de bug na API
          //Para essas versões não pode-se realizar a linha abaixo
          //this.currentConfig.quickRollRightActionA = this.convertUndefinedActionToDNC(undefined);
        }
        else if (this.currentConfig.firmwareRevision >= 23){
          this.currentConfig.quickRollRightActionA = this.convertUndefinedActionToDNC(
            this.holdableActions.find(element => element.id === param.value.quickRollRightActionA));
        }
        this.currentConfig.quickRollRightActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.quickRollRightActionB));
        this.currentConfig.quickRollLeftActionA = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.quickRollLeftActionA));
        this.currentConfig.quickRollLeftActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.quickRollLeftActionB));
        if (this.nextConfig.quickRollRightActionA.id === undefined && this.currentConfig.firmwareRevision >= 23) {
          this.nextConfig.quickRollRightActionA = this.currentConfig.quickRollRightActionA;}
        if (this.nextConfig.quickRollRightActionB.id === undefined) {
          this.nextConfig.quickRollRightActionB = this.currentConfig.quickRollRightActionB;
          this.nextConfig.quickRollLeftActionA = this.currentConfig.quickRollLeftActionA;
          this.nextConfig.quickRollLeftActionB = this.currentConfig.quickRollLeftActionB;
        }
        this.configUpdatedBS.next({
          quickRollRightActionA:this.currentConfig.quickRollRightActionA,
          quickRollRightActionB:this.currentConfig.quickRollRightActionB,
          quickRollLeftActionA:this.currentConfig.quickRollLeftActionA,
          quickRollLeftActionB:this.currentConfig.quickRollLeftActionB
        });
        break;

      case ColibriApiParamID.actionNodding:
        //console.log(JSON.stringify(param.value));
        this.currentConfig.xNoddingActionA = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.xNoddingActionA));
        this.currentConfig.xNoddingActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.xNoddingActionB));
        this.currentConfig.yNoddingActionA = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.yNoddingActionA));
        this.currentConfig.yNoddingActionB = this.convertUndefinedActionToDNC(
          this.holdableActions.find(element => element.id === param.value.yNoddingActionB));
        // if (this.nextConfig.xNoddingActionA.id === undefined) {
        //   this.nextConfig.xNoddingActionA = this.currentConfig.xNoddingActionA;
        //   this.nextConfig.xNoddingActionB = this.currentConfig.xNoddingActionB;
        //   this.nextConfig.yNoddingActionA = this.currentConfig.yNoddingActionA;
        //   this.nextConfig.yNoddingActionB = this.currentConfig.yNoddingActionB;
        // }
        // TODO 67: substituir por um obs que atualiza o patch no store, a estrutura é a mesma da Lai
        this.configUpdatedBS.next({
          xNoddingActionA:this.currentConfig.xNoddingActionA,
          xNoddingActionB:this.currentConfig.xNoddingActionB,
          yNoddingActionA:this.currentConfig.yNoddingActionA,
          yNoddingActionB:this.currentConfig.yNoddingActionB
        });
        break;

      case ColibriApiParamID.xZeroZone:
        this.currentConfig.xZeroZone = param.value;
        if (this.nextConfig.xZeroZone === undefined) { this.nextConfig.xZeroZone = this.currentConfig.xZeroZone; }
        this.configUpdatedBS.next({
          xZeroZone: this.currentConfig.xZeroZone,
        });
        break;

      case ColibriApiParamID.yZeroZone:
        this.currentConfig.yZeroZone = param.value;
        if (this.nextConfig.yZeroZone === undefined) { this.nextConfig.yZeroZone = this.currentConfig.yZeroZone; }
        this.configUpdatedBS.next({
          yZeroZone: this.currentConfig.yZeroZone,
        });
        break;

      case ColibriApiParamID.clickSensitivity:
        this.currentConfig.clickSensitivity = param.value;
        if (this.nextConfig.clickSensitivity === undefined) { this.nextConfig.clickSensitivity = this.currentConfig.clickSensitivity; }
        this.configUpdatedBS.next({
          clickSensitivity: this.currentConfig.clickSensitivity,
        });
        break;

      case ColibriApiParamID.clickTimeControl:
        this.currentConfig.clickTime = Math.abs(param.value) * 100;
        this.currentConfig.dwellClick = (param.value > 0);
        this.currentConfig.clickTimeFilter = (param.value < 0);
        if (this.nextConfig.clickTime === undefined) {
          this.nextConfig.clickTime = this.currentConfig.clickTime;
          this.nextConfig.dwellClick = this.currentConfig.dwellClick;
          this.nextConfig.clickTimeFilter = this.currentConfig.clickTimeFilter;
        }
        this.configUpdatedBS.next({
          clickTime: this.currentConfig.clickTime,
          dwellClick: this.currentConfig.dwellClick,
          clickTimeFilter: this.currentConfig.clickTimeFilter
        });
        break;

      case ColibriApiParamID.rawSensorOutput:
        this.currentConfig.rawSensorOutput = param.value;
        if (this.nextConfig.rawSensorOutput === undefined) { this.nextConfig.rawSensorOutput = this.currentConfig.rawSensorOutput; }
        this.configUpdatedBS.next({
          rawSensorOutput: this.currentConfig.rawSensorOutput,
        });
        break;

      case ColibriApiParamID.logLevel:
        this.currentConfig.logLevel = param.value;
        if (this.nextConfig.logLevel === undefined) { this.nextConfig.logLevel = this.currentConfig.logLevel; }
        this.configUpdatedBS.next({
          logLevel: this.currentConfig.logLevel,
        });
        break;

      case ColibriApiParamID.gestureSpeed:
        this.currentConfig.gestureSpeedLevel = param.value;
        this.currentConfig.yesNoLevel = (5 - param.value);
        if (this.nextConfig.gestureSpeedLevel === undefined) {
          this.nextConfig.gestureSpeedLevel = this.currentConfig.gestureSpeedLevel;
          this.nextConfig.yesNoLevel = this.currentConfig.yesNoLevel;
        }
        this.configUpdatedBS.next({
          gestureSpeedLevel: this.currentConfig.gestureSpeedLevel,
          yesNoLevel: this.currentConfig.yesNoLevel,
        });
        break;

      case ColibriApiParamID.gestureIntensity:
        this.currentConfig.gestureIntensityLevel = param.value;
        this.currentConfig.yesNoLevel = (5 - param.value);
        if (this.nextConfig.gestureIntensityLevel === undefined) {
          this.nextConfig.gestureIntensityLevel = this.currentConfig.gestureIntensityLevel;
          this.nextConfig.yesNoLevel = this.currentConfig.yesNoLevel;
        }
        this.configUpdatedBS.next({
          gestureIntensityLevel: this.currentConfig.gestureIntensityLevel,
          yesNoLevel: this.currentConfig.yesNoLevel,
        });
        break;

      case ColibriApiParamID.movementTimeControl:
        this.currentConfig.movementTime = Math.abs(param.value) * 100;
        this.currentConfig.stopDuringClick = (param.value > 0);
        this.currentConfig.movementTimeFilter = (param.value < 0);
        if (this.nextConfig.movementTime === undefined) {
          this.nextConfig.movementTime = this.currentConfig.movementTime;
          this.nextConfig.stopDuringClick = this.currentConfig.stopDuringClick;
          this.nextConfig.movementTimeFilter = this.currentConfig.movementTimeFilter;
        }
        this.configUpdatedBS.next({
          movementTime: this.currentConfig.movementTime,
          stopDuringClick: this.currentConfig.stopDuringClick,
          movementTimeFilter: this.currentConfig.movementTimeFilter
        });
        break;

      case ColibriApiParamID.hourMeter:
        this.currentConfig.hourMeter = param.value;
        if (this.nextConfig.hourMeter === undefined) { this.nextConfig.hourMeter = this.currentConfig.hourMeter; }
        this.configUpdatedBS.next({
          hourMeter: this.currentConfig.hourMeter
        });
        break;

      case ColibriApiParamID.gestureTime:
        this.currentConfig.gestureTime = param.value;
        if (this.nextConfig.gestureTime === undefined) { this.nextConfig.gestureTime = this.currentConfig.gestureTime; }
        this.configUpdatedBS.next({
          gestureTime: this.currentConfig.gestureTime
        });
        break;

      default:
        console.log('paramUpdated unknown param', param.id);
    }

    // Notifica atualização do parâmetro para interessados
    this.paramUpdatedBS.next(param);
  }

  // Comparators
  operationModeCompareWith(o1: IOperationMode, o2: IOperationMode) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  clearConfigData() {
    return {
      // READ WRITE Parameters
      configVersion: undefined,
      apiVersion: undefined,
      hardwareVersion: undefined,
      firmwareVersion: undefined,
      firmwareRevision: undefined,
      operationMode: {id: undefined, name: 'loading'},
      buzzerLevel: undefined,
      buzzerLevelClick: undefined,
      xSpeed: undefined,
      ySpeed: undefined,
      scrollSpeed: undefined,
      xSpeedProgressive: undefined,
      ySpeedProgressive: undefined,
      scrollSpeedProgressive: undefined,
      rollAngle: undefined,
      pitchAngle: undefined,
      rollRightActionA: {id: undefined, name: 'loading'},
      rollRightActionB: {id: undefined, name: 'loading'},
      rollLeftActionA: {id: undefined, name: 'loading'},
      rollLeftActionB: {id: undefined, name: 'loading'},
      quickRollRightActionA: {id: undefined, name: 'loading'},
      quickRollRightActionB: {id: undefined, name: 'loading'},
      quickRollLeftActionA: {id: undefined, name: 'loading'},
      quickRollLeftActionB: {id: undefined, name: 'loading'},
      xNoddingActionA: {id: undefined, name: 'loading'},
      xNoddingActionB: {id: undefined, name: 'loading'},
      yNoddingActionA: {id: undefined, name: 'loading'},
      yNoddingActionB: {id: undefined, name: 'loading'},
      // yNoddingSpeed: undefined,
      // xNoddingSpeed: undefined,
      xZeroZone: undefined,
      yZeroZone: undefined,
      clickSensitivity: undefined,
      clickTime: undefined,
      dwellClick: undefined,
      clickTimeFilter: undefined,
      rawSensorOutput: undefined,
      logLevel: undefined,
      gestureSpeedLevel: undefined,
      gestureIntensityLevel: undefined,
      yesNoLevel: undefined,
      movementTime: undefined,
      stopDuringClick: undefined,
      movementTimeFilter: undefined,
      hourMeter: undefined,
      gestureTime: undefined,
    } as IColibriConfig;
  }

  private convertUndefinedActionToDNC(action: IGestureAction) {
    if(action === undefined) {
      return {id: ColibriApiAction.doNotChange, name:  'loading'} as IGestureAction;
    }
    if(action.id === undefined) {
      return {id: ColibriApiAction.doNotChange, name:  'loading'} as IGestureAction;
    }
    return action;
  }

}
