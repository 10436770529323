/* eslint-disable @typescript-eslint/naming-convention */
import { IColibriConfig, ISerial } from 'src/app/colibriHAL/IColibriConfig';
import { SessionFirestoreService } from './../database/firestore/session-firestore.service';
import { ColibriHALService } from './../colibriHAL/colibriHAL.service';
import { Injectable } from '@angular/core';
import { DebounceQueue } from 'rx-queue';
import { AuthService } from '../auth/auth.service';
import { ISession, IProfile } from './session.model';
import { UserService } from '../user/user.service';
import { SessionStoreService } from '../database/store/session-store.service';
import { environment } from 'src/environments/environment';
import { LogService } from '../log/log.service';
import { IRPYSensor } from '../colibriHAL/colibriAPI.service';
import { BehaviorSubject } from 'rxjs';
import { ConfigProfileService } from '../config/config-profile/config-profile.service';
import { Timestamp } from 'firebase/firestore';
import { CreateNewProfileComponent } from '../components/create-new-profile/create-new-profile.component';
import { ModalController } from '@ionic/angular'; //TESTE

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  isConfigColibriStarted: boolean;
  initializedSession = false;
  initConfig: IColibriConfig;
  docID;
  actualProfile: {id: string; data: IProfile};

  colorClass; // Nome da classe que colore os detalhes
  colorClassMove; // Nome da classe que colore os detalhes da page Movement-test

  statusUpdateSubscription;
  configColibriUpdateSubscription;
  stateStoreUpdateSubscription;
  colibriSerialSubscription;
  syncWriteQ = new DebounceQueue(400);
  syncFireStoreQ = new DebounceQueue(400);

  colibriRPYDataBS: BehaviorSubject <IRPYSensor>;

  constructor(private colibri: ColibriHALService,
    private firestore: SessionFirestoreService,
    public sessionStore: SessionStoreService,
    private auth: AuthService,
    private log: LogService,
    private user: UserService,
    public profile: ConfigProfileService,
    public modalController: ModalController,) {
      this.sessionStore.set(this.emptySession(),'create empty session: constructor');

      // if (!environment.production) {
      //   console.groupCollapsed('Init State');
      //   console.log('currentConfig Colibri' ,this.colibri.currentConfig);
      //   console.log('nextConfig Colibri' ,this.colibri.nextConfig);
      //   console.log('config page', this.sessionStore.state);
      //   console.groupEnd();
      // }

      this.initConfig = this.colibri.currentConfig;
      //console.log('[ON INIT]', this.initConfig);

      // Filas de sincronização
      this.syncWriteQ.subscribe((x: string) => this.writeQueueExecuter(x));
      this.syncFireStoreQ.subscribe(() => this.syncFireStoreQueueExecuter());

      //Observável da alteração das config no Colibri
      this.configColibriUpdateSubscription = this.colibri.configUpdatedBS.subscribe((config) => this.reactToColibriConfigUpdated(config));
      //Observável do status do Colibri
      this.statusUpdateSubscription = this.colibri.statusUpdatedBS.subscribe((status) => {
        switch (status) {
          case 'connected':
            this.initConfigColibri();
            this.createFirebaseDoc();
            break;
            case 'disconnected':
            // TODO: modo off?
            break;
          default:
            break;
        }
      });
      //Observável da alteração do estado do Store
      this.stateStoreUpdateSubscription = this.sessionStore.state$.subscribe((state) => {
        // Atualização do estado de next para o funcionamento adequado do HAL
        this.colibri.nextConfig = this.sessionStore.state.config;
        this.syncFireStoreQ.next('');
      });

      this.colibriSerialSubscription = this.colibri.colibriSerialBS.subscribe((serial: ISerial) => {
        this.onColibriColorChanged(serial);
      });

      this.colibriRPYDataBS = this.colibri.colibriRPYDataBS;
    }

  connect() {
    this.colibri.connect();
  }

  finish() {
    this.isConfigColibriStarted = false;
    this.initializedSession = true;
    this.colibri.disconnect();
  }

  commitColibri() {
    return this.colibri.commit();
  }

  async createProfile() {
    const modal = await this.modalController.create({
      component: CreateNewProfileComponent,
      cssClass: 'modal-create-new-profile',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    //console.log('MODAL Data',data);
    const newProfile = this.clearProfile(data);;
    this.profile.create(newProfile);
  }

  saveProfile() {
    this.actualProfile.data.config = this.sessionStore.state.config;
    this.actualProfile.data.editedTimestamp = Timestamp.now();
    this.actualProfile.data = this.removeEmpty(this.actualProfile.data);
    this.profile.save(this.actualProfile);
  }

  //Chamado quando o perfil é selecionado
  loadProfile(profile: {id: string; data: IProfile}) {
    this.actualProfile = profile;
  }

  deleteProfile() {
    this.profile.delete(this.actualProfile);
  }

  importProfile(ev) {
    this.profile.import(ev);
  }

  exportProfile() {
    this.profile.export(this.actualProfile);
  }

  rpySensorEnable() {
    this.colibri.rpySensorEnable(true);
  }

  rpySensorDisable() {
    this.colibri.rpySensorEnable(false);
  }

  factoryResetConfig() {
    this.colibri.factoryReset();
  }

  revertConfig() {
    this.sessionStore.state.config = this.initConfig;
    console.log('[Revert]', this.sessionStore.state.config);
    this.syncWriteQ.next('revertConfig');
  }

  writeQueueExecuter(action: string) {
    // O print gerado pelo patch da fila não mostra a mudança só que ocorreu
    // A mudança é feita diretamente pelo ngModel no html
    this.sessionStore.patch({},'[Queue] '+ action);
    if (!environment.production) {
      console.groupCollapsed('Sync');
      console.log('currentConfig Colibri', this.colibri.currentConfig);
      console.log('nextConfig Colibri', this.colibri.nextConfig);
      console.log('config page', this.sessionStore.state.config);
      console.groupEnd();
    }
    this.colibri.synchronize().then(() => {}, () => {});
  }

  // Realiza a atualização dos dados no firestore(nuvem)
  // Envia os dados presentes no Store
  syncFireStoreQueueExecuter() {
    if (this.sessionStore.state.ownerUid === undefined || this.docID === undefined) { return; }

    const update = this.removeEmpty(this.sessionStore.state);

    if (!environment.production) {
      console.groupCollapsed('Update cloud');
      console.log('state', this.sessionStore.state);
      console.log('update', update);
      console.groupEnd();
    }

    this.firestore.updateTimestamp(update, this.docID);
  }

  // Reage a informações de cor do colibri e traduz cor para classe css
  onColibriColorChanged(serial: ISerial) {

    // k-black, a-gray, b-blue, p-pink, r-red, o-orange, g-green, u-purple

    // CONJUNTO DE CORES
    enum colorMapEnum {
      k = 'gray',
      a = 'gray',
      b = 'blue',
      p = 'pink',
      r = 'red',
      o = 'orange',
      g = 'green',
      u = 'purple',
      x = 'navy'
    }
    // CONJUNTO DE CLASSES DEFINIDAS NO GLOBAL
    enum colorMapMoveEnum {
      k = 'gray-color',
      a = 'gray-color',
      b = 'blue-color',
      p = 'pink-color',
      r = 'red-color',
      o = 'orange-color',
      g = 'green-color',
      u = 'purple-color',
      x = 'navy-color'
    }

    this.colorClass = colorMapEnum[serial.color];
    this.colorClassMove = colorMapMoveEnum[serial.color];
    // console.log('[onColibriColorChanged]', serial, this.colorClassMove);

    //console.log('[onColibriColorChanged]', serial, this.colorClass);

  }

  // Função que realiza o patch no store, é chamada quando uma config do colibri é alterada
  reactToColibriConfigUpdated(config) {
    if (config) {
      const newState = Object.assign({}, this.sessionStore.state.config, config);
      const update = {
        config: newState as IColibriConfig
      };
      this.sessionStore.patch(update,'[React] '+ JSON.stringify(config));
    }
  }

  async createFirebaseDoc() {
    if(this.auth.isLoggedIn && !this.initializedSession) { // Sincronizar com firestore se estiver logado
      this.sessionStore.state.ownerUid = this.user.uid;
      console.log('create:', this.sessionStore.state);
      const document = this.removeEmpty(this.sessionStore.state);
      this.docID = await this.firestore.create(document);
    } else { // Se não estiver logado

    }
    this.initializedSession = true;
  }

  // Realiza a leitura dos parâmetros do colibri
  async initConfigColibri() {
    this.isConfigColibriStarted = true;

    if (await this.colibri.identifyColibri()) {
      this.log.logColibriEvent(
        this.colibri.colibriID,
        this.colibri.colibriSerial,
        this.colibri.colibriColor);

      await this.colibri.readAll();

      setTimeout(() => {
        this.colibri.readAll();
      }, 1500);

      setTimeout(() => {
        if (!environment.production) {
          console.groupCollapsed('State');
          console.log('currentConfig Colibri' ,this.colibri.currentConfig);
          console.groupEnd();
        }
      }, 3000);
      return Promise.resolve('OK');
    } else {
      await this.colibri.readAll();
      return Promise.reject('identifyColibri error');
    }
  }

  // Função recursiva que remove undefined dos objetos
  private removeEmpty = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === Object(obj[key])) {newObj[key] = this.removeEmpty(obj[key]);}
      else if (obj[key] !== undefined) {newObj[key] = obj[key];}
    });
    return newObj;
  };

  private emptySession() {
    return {
      timestamp: undefined,
      creationTimestamp: undefined,
      ownerUid: this.user.uid,
      guestUid: [],
      config: this.colibri.clearConfigData(),
      wizard: this.clearWizard()
    } as ISession;
  }

  private clearWizard() {
    return {
      persona: undefined,
      feedback: undefined,
      rangeTop: undefined,
      rangeBottom: undefined,
      rangeLeft: undefined,
      rangeRight: undefined,
      rangeRollLeft: undefined,
      rangeRollRight: undefined
    };
  }

  private clearProfile(data) {
    return {
      timestamp: Timestamp.now(),
      creationTimestamp: Timestamp.now(),
      ownerUid: '',
      name: data.name,
      config: this.removeEmpty(this.sessionStore.state.config)
    } as IProfile;
  }

}
