import { NotificationService } from './../notification.service';
import { LanguageService } from 'src/app/language/language.service';
import { Platform, NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  activeModal = false;
  textOfModal;
  typeOfModal;
  msgConfirmBtn;
  msgDenyBtn;

  constructor(public lang: LanguageService, private navCtrl: NavController, public plt: Platform,
  private notification: NotificationService) { }

  ngOnInit() {
    this.setStyleOfModal();
    this.activeModal = true;
  }

  setStyleOfModal() {
    this.typeOfModal = this.notification.identifyTypeOfModal();
    this.msgConfirmBtn = this.lang.words.common.ok;

    switch (this.typeOfModal) {
      case 'timeout':
        if (this.plt.is('capacitor')){
          this.textOfModal = this.lang.words.notifications.modals.timeout.native;
        } else {
          this.textOfModal = this.lang.words.notifications.modals.timeout.web;
        }
        break;
      case 'maxColibrisPared':
        this.textOfModal = this.lang.words.notifications.modals.maxColibri;
        break;
      case 'anyColibriPared':
        this.textOfModal = this.lang.words.notifications.modals.anyColibri;
        break;
      case 'BLE_disabled':
        this.textOfModal = this.lang.words.notifications.modals.bleDisabled.text;
        this.msgConfirmBtn = this.lang.words.notifications.modals.bleDisabled.confirm;
        this.msgDenyBtn = this.lang.words.notifications.modals.bleDisabled.deny;
        break;
      default:
        this.textOfModal = this.lang.words.common.error;
        break;
    }
    console.log('[TESTE MODAL]:', this.typeOfModal, this.textOfModal);
  }

  onConfirmBtnClicked() {
    console.log('[TESTE MODAL]:', this.typeOfModal);
    switch(this.typeOfModal){
      case 'timeout':
        this.notification.feedbackOfModal('timeout');
        if (this.plt.is('capacitor')){
          this.navCtrl.navigateForward('/home');
        } else {
          this.navCtrl.navigateForward('/turn-on');
        }
        break;
      case 'maxColibrisPared':
        this.notification.loaderControl('ON', 5, 0); // Ação de conectar
        this.notification.feedbackOfModal('maxColibrisPared');
        this.navCtrl.navigateForward('/home');
        //TODO: Implementar a ida direta ao painel do bluetooth
        break;
      case 'anyColibriPared':
        this.notification.feedbackOfModal('anyColibriPared');
        this.navCtrl.navigateForward('/home');
        //TODO: Implementar a ida direta ao painel do bluetooth
        break;
      case 'BLE_disabled':
        this.notification.feedbackOfModal('enabledBLE');
        break;
      default:
        break;
    }
    this.notification.closeModal();
  }

  onDenyBtnClicked() {
    console.log('[TESTE MODAL]:', this.typeOfModal);
    switch (this.typeOfModal) {
      case 'BLE_disabled':
        this.navCtrl.navigateForward('/home');
        this.notification.feedbackOfModal('disabledBLE');
        break;
      default:
        break;
    }
    this.notification.closeModal();
  }

}
