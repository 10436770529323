import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/language/language.service';
import { UserService } from 'src/app/user/user.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-auth-icon-btn-popover',
  templateUrl: './auth-icon-btn-popover.component.html',
  styleUrls: ['./auth-icon-btn-popover.component.scss'],
})
export class AuthIconBtnPopoverComponent implements OnInit, OnDestroy {

  createGroupOptionAvailable = false;
  mediasDownloadProgressSub: Subscription;
  mediasDownloadProgress = { isDownloading: false, processed: 0, total: 0, percentage: 0 };

  constructor(public lang: LanguageService, private popoverController: PopoverController,
              private navCtrl: NavController, protected authService: AuthService, private modalController: ModalController,
              public userService: UserService) { }

  ngOnInit() {
    // The option to create a group is only available if the the user is on acc or activities pages
    if (window.location.pathname === '/activities' || window.location.pathname === '/aac') {
      this.createGroupOptionAvailable = true;
    }

    // /* Watch changes on medias download progress */
    // this.mediasDownloadProgressSub = this.mediaStorageService.downloadProgressObs.subscribe(progress => {
    //   this.mediasDownloadProgress = progress;
    // });
  }

  ngOnDestroy(): void {
    if (this.mediasDownloadProgressSub) { this.mediasDownloadProgressSub.unsubscribe(); }
  }

  async onUserProfileBtnClicked() {
    this.popoverController.dismiss();
  //   const modal = await this.modalController.create({
  //     component: UserProfileComponent,
  //   });
  //   await modal.present();
  }

  onSignInBtnClicked() {
    this.popoverController.dismiss();
    this.navCtrl.navigateForward('/login');
  }

  onCancelSubscriptionBtnClicked() {
    this.popoverController.dismiss();
    // Testa se há assinatura ativa do Android
    // if (this.app.user.subscription.origin === 'in-app-google') {
    //   // Encaminha para gerenciador do google play WEB se estiver no navegador
    //   if (this.app.isRunningOnBrowser()) {
    //     window.location.href = 'https://play.google.com/store/account/subscriptions';
    //   } else {
    //     // Encaminha para gerenciador do google play se estiver no android
    //     this.subscriptionService.storeManageSubscriptions();
    //   }
    // } else {
    //   // TODO: tratar iOS e outros aqui, quando houver outros apps nativos
    //   // Se não há assinatura ativa no Android e outros
    //   this.subscriptionService.stripeManageSubscriptions();
    // }
    // this.analytics.setUserProperties({ subscriptionIsActive: this.app.user.subscription.isActive });
  }

  async onLogoutBtnClicked() {
    this.authService.signOut()
      .then(() => { this.popoverController.dismiss(); })
      .catch(() => { });
  }

  onVerifyEmailBtnClicked() {
    return this.authService.sendVerificationMail();
  }
}
