import { Injectable } from '@angular/core';
import { LanguageWords, LanguageWordsRelease } from './language.model';
import { BehaviorSubject } from 'rxjs';
import * as LangPtBrJson from './languages/pt-BR.json';
import * as LangPtBrCorpJson from './languages-release/pt-BR-corp.json';
import * as LangArSaJson from './languages/ar-SA.json';
import * as LangDeDeJson from './languages/de-DE.json';
import * as LangEsEsJson from './languages/es-ES.json';
import * as LangFrFrJson from './languages/fr-FR.json';
import * as LangHeIlJson from './languages/he-IL.json';
import * as LangItItJson from './languages/it-IT.json';
import * as LangEnUSJson from './languages/en-US.json';
import * as LangReleasePtBrJson from './languages-release/pt-BR.json';
import * as LangReleaseEnUsJson from './languages-release/en-US.json';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  words: LanguageWords;
  wordsRelease: LanguageWordsRelease;
  langObs;

  locale = 'en_US';
  languageCode = 'en-US';
  languagePrefix = 'en';

  private langBS = new BehaviorSubject<string>('');
  private environmentName = environment.environmentName;

  constructor() {

    this.langObs = this.langBS.asObservable();
    // Sets the language to default first.
    //this.setLang('pt-BR');

    // Reads the last language code the app was using and set the current language.

    // If the user hasn't set the language yet, we read the preferred language from the browser
    // and set the app language accordingly.
    const userPreferredLangs = window.navigator.languages || [window.navigator.language];

    if ( this.environmentName === 'TL') {
      this.setLang('pt-BR-corp');
    } else if (userPreferredLangs[0].includes('pt')) {
      this.setLang('pt-BR');
    } else if (userPreferredLangs[0].includes('fr')) {
      this.setLang('fr-FR');
    } else if (userPreferredLangs[0].includes('es')) {
      this.setLang('es-ES');
    } else if (userPreferredLangs[0].includes('ar')) {
      this.setLang('ar-SA');
    } else if (userPreferredLangs[0].includes('de')) {
      this.setLang('de-DE');
    } else if (userPreferredLangs[0].includes('es')) {
      this.setLang('es-ES');
    } else if (userPreferredLangs[0].includes('fr')) {
      this.setLang('fr-FR');
    } else if (userPreferredLangs[0].includes('he')) {
      this.setLang('he-IL');
    } else if (userPreferredLangs[0].includes('it')) {
      this.setLang('it-IT');
    } else {
      // Falls back to English
      this.setLang('en-US');
    }

    console.log(userPreferredLangs[0]);
  }

  /**
   * Sets the current language that the app is using. This will translate all words in the app layout (not on contents like boards or cards)
   * to the selected language.
   *
   * @param langCode ISO Language code (http://www.lingoes.net/en/translator/langcode.htm)
   */
  setLang(langCode: string) {
    let idiom: LanguageWords;
    const enUS: LanguageWords = (LangEnUSJson as any).default;

    this.locale = langCode.replace('-','_');
    this.languageCode = langCode;
    this.languagePrefix = langCode.split('-')[0];

    this.wordsRelease = (LangReleaseEnUsJson as any).default;



    switch (langCode) {
      case 'pt-BR':
        idiom = (LangPtBrJson as any).default;
        this.wordsRelease = (LangReleasePtBrJson as any).default;
      break;
      case 'ar-SA':
        idiom = (LangArSaJson as any).default;
      break;
      case 'de-DE':
        idiom = (LangDeDeJson as any).default;
      break;
      case 'es-ES':
        idiom = (LangEsEsJson as any).default;
      break;
      case 'fr-FR':
        idiom = (LangFrFrJson as any).default;
      break;
      case 'he-IL':
        idiom = (LangHeIlJson as any).default;
      break;
      case 'it-IT':
        idiom = (LangItItJson as any).default;
      break;
      case 'pt-BR-corp':
        idiom = (LangPtBrCorpJson as any).default;
      break;
      default:
        idiom = (LangEnUSJson as any).default;
      break;
    }

    this.words = this.mergeDeep(enUS, idiom);
    // Set and save the current language so the next time the app will be presented on that language
    this.langBS.next(langCode);
  }

  /**
   * Simple object check.
   *
   * @param item
   * @returns {boolean}
   */

  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  /**
   * Deep merge two objects.
   *
   * @param target
   * @param ...sources
   */
  mergeDeep(target, ...sources) {
    if (!sources.length) {return target;}
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {Object.assign(target, { [key]: {} });}
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }
}
