import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'platform-not-supported',
    loadChildren: () => import('./platform-not-supported/platform-not-supported.module').then( m => m.PlatformNotSupportedPageModule)
  },
  {
    path: 'turn-on',
    loadChildren: () => import('./tutorial/turn-on/turn-on.module').then( m => m.TurnOnPageModule)
  },
  {
    path: 'led-state',
    loadChildren: () => import('./tutorial/led-state/led-state.module').then( m => m.LedStatePageModule)
  },
  {
    path: 'loading',
    loadChildren: () => import('./loading/loading.module').then( m => m.LoadingPageModule)
  },
  {
    path: 'loading/:op',
    loadChildren: () => import('./loading/loading.module').then( m => m.LoadingPageModule)
  },
  {
    path: 'colibri-not-found',
    loadChildren: () => import('./colibri-not-found/colibri-not-found.module').then( m => m.ColibriNotFoundPageModule)
  },
  {
    path: 'more-resources',
   loadChildren: () => import('./more-resources/more-resources.module').then( m => m.MoreResourcesPageModule)
  },
  {
    path: 'debug',
   loadChildren: () => import('./debug/debug.module').then( m => m.DebugModule)
  },
  {
    path: 'what-is-colibri',
    loadChildren: () => import('./what-is-colibri/what-is-colibri.module').then( m => m.WhatIsColibriPageModule)
  },
  {
    path: 'tutorial-assistive-touch',
    loadChildren: () => import('./tutorial-assistive-touch/tutorial-assistive-touch.module').then( m => m.TutorialassistiveTouchPageModule)
  },
  {
    path: 'bluetooth-ios',
    loadChildren: () => import('./bluetooth-ios/bluetooth-ios.module').then( m => m.BluetoothIosPageModule)
  },
  {
    path: 'bluetooth-android',
    loadChildren: () => import('./bluetooth-android/bluetooth-android.module').then( m => m.BluetoothAndroidPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./auth/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'profile-choice',
    loadChildren: () => import('./profile/profile-choice/profile-choice.module').then( m => m.ProfileChoicePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
