import { Injectable } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { ProfileFirestoreService } from 'src/app/database/firestore/profile-firestore.service';
import { IProfile } from 'src/app/session/session.model';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConfigProfileService {

  userSubscription: Subscription;
  profileSubscription: Subscription;
  listOfProfiles: {id: string; data: IProfile}[];
  uid;
  constructor(private user: UserService,
    private profile: ProfileFirestoreService) {
    this.userSubscription = user.uid$.subscribe((uid) =>{
      profile.basePath = 'users/' + uid + '/profiles';
      this.uid = uid;
      console.log('[Profile][basePath]', profile.basePath);

      if (this.profileSubscription) {
        this.profileSubscription.unsubscribe();
      }

      this.profileSubscription = profile.collectionSnapshot$().subscribe((collection) =>{
        console.log('[Profile][collection]', collection);
        this.listOfProfiles = collection;
        //console.log('[Profile][collection]', this.listOfProfiles);
      });
    });
  }

  async create(newProfile: IProfile) {
    newProfile.ownerUid = this.uid;
    console.log('[Profile Create]',newProfile);
    await this.profile.create(newProfile);
  }

  load() {
    return this.listOfProfiles;
  }

  save(change: {id: string; data: IProfile}) {
    console.log('[Profile Save]');
    this.profile.update(change.data,change.id);
  }

  delete(actualProfile: {id: string; data: IProfile}) {
    console.log('[Profile Delete]', actualProfile);
    this.profile.delete(actualProfile.id);
  }

  export(actualProfile) {
    console.log('[Profile EXPORT]');
    const arquive = JSON.stringify(actualProfile);
    this.download(actualProfile.data.name, arquive);
  }

  import(ev: any) {
    const file = ev.target.files[0];
    console.log('[Profile IMPORT]', ev, file);
    let reader = new FileReader();

    // Is this a "real" file? In other words, is this an instance of the original `File` class
    // (not the one overriden by cordova-plugin-file). If so, then we need to use the "real"
    // FileReader (not the one overriden by cordova-plugin-file).
    if (file instanceof Blob) {
      // eslint-disable-next-line no-underscore-dangle
      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }
    }

    reader.onload = e => {
      const arquive = JSON.parse(reader.result.toString());
      console.log('[DADO IMPORT]',arquive);
      this.create(arquive.data);
    };

    reader.onerror = (error) => {
      console.log(error);
    };

    reader.readAsText(file);
  }

  private download(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename + '.colibri');

    //Criação de um btn invisível para realizar a ação de download
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
