import { NotificationService } from './../notifications/notification.service';
import { Injectable } from '@angular/core';
import { BluetoothLE, CurrConnectionStatus, OperationResult } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { ColibriApiMessageType } from '../colibriHAL/colibriApiValues.enum';

enum ConnMonState {
  uninitialized,
  initializing,
  idle,
  scanning,
  searchingPaired,
  connecting,
  connected,
  reconnecting,
  disconnecting,
  disconnected,
  connectedWithError
}

@Injectable({
  providedIn: 'root'
})
export class BleService {

  bleInitSub: Subscription;
  bleScanSub: Subscription;
  readCharacteristicSub: Subscription;
  configServiceUUID = 'C0F1';
  writeCharacteristicUUID = 'F000A111-0451-4000-B000-000000000000';   // fill in a characteristic from the service here
  readCharacteristicUUID = 'F000A110-0451-4000-B000-000000000000';   // fill in a characteristic from the service here
  deviceAddr = ''; // MAC Address informed by bluetooth Adapter (may be random, partial or obscured)
  deviceName = '';
  pairedDeviceList = [];

  myServer;
  myDevice; // Web
  myServiceID = 0xC0F1;
  service;
  writeCharacteristic;   // fill in a characteristic from the service here
  readCharacteristic;   // fill in a characteristic from the service here

  bleStatusBS = new BehaviorSubject('BLE_INIT');
  bleStatusObs = this.bleStatusBS.asObservable();
  connectionObs;  // Connection Status
  bleReadCharacteristicObs; // Read characteristic subscription
  scanTimeout = null;

  connectionModal: HTMLIonModalElement;
  reconnectionTimeout;
  isConnected = false;
  resetBluetoothLE = false; // If true should disconnect and reconnect as if reopening the app
  errorCounter = 0;
  successCounter = 0;
  connectionAttempts = 0;

  connMonCounter = 0;
  connMonState: ConnMonState = ConnMonState.uninitialized;
  desiredConnMonState: ConnMonState = ConnMonState.idle;

  private exponentialBackoffMin = 8;
  private exponentialBackoffMax = 64;
  private exponentialBackoff = 8;
  private exponentialBackoffTimeout = 2;
  private webBluetoothIdle = true;

  // Function to call when notification messages are received
  private notificationHandler: (msg: Uint8Array) => void;
  private navigator: any = window.navigator;

  constructor(public bluetoothLe: BluetoothLE, public plt: Platform, private device: Device, private notification: NotificationService) {
    //console.log('BLE Service init');
    setInterval(() => {this.connectionMonitorFSM();}, 1000);
  }

  backOff(reset = false) {

    const ret = this.exponentialBackoff;
    this.exponentialBackoff *= 2;

    if (this.exponentialBackoff > this.exponentialBackoffMax) {
      this.exponentialBackoff = this.exponentialBackoffMin;
    }
    if (reset) {
      this.exponentialBackoff = this.exponentialBackoffMin;
    }
    //console.log('backOff', this.exponentialBackoff);
  }

  /**
   * Monitors and controls bluetooth connection
   */
  async connectionMonitorFSM() {
    console.log('CMFSM', ConnMonState[this.connMonState], '->' ,ConnMonState[this.desiredConnMonState],
    this.isConnected, this.successCounter);
    switch (this.connMonState) {
      // Initialize bluetooth
      case ConnMonState.uninitialized:
        this.connectionMonitorInitialize();
        break;
      // Initialize bluetooth
      case ConnMonState.initializing:
        if (this.connMonCounter > 30) {
          this.connectionMonitorDisconnect();
        }
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        // Transition to idle in connectionMonitorInitialize
        break;
      // Ready for connections
      case ConnMonState.idle:
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        if (this.desiredConnMonState === ConnMonState.connected) {
          if (this.pairedDeviceList.length) {
            this.connMonCounter = 0;
            this.connectionMonitorConnect();
          }
          else if (this.plt.is('capacitor')) {
            this.connectionMonitorSearchPaired();
          }
          else { // Web
            this.connectionMonitorConnect();
          }
        }
        // eslint-disable-next-line max-len
        // if (!this.plt.is('capacitor') && this.desiredConnMonState === ConnMonState.idle) { // Caso de entrar diretamente no config pela web
        //   const loading = 11;
        //   this.notification.loaderControl('ON', loading).then(()=>{},()=>{});
        // }
        if (this.desiredConnMonState === ConnMonState.disconnected) {
          // Condição adicionada devido o redirecionamento dos modais
          this.connectionMonitorDisconnect();
          // this.status.notification('loader', ConnMonState.disconnected).then(res => {}, err => {});;
          this.notification.loaderControl('OFF', ConnMonState.disconnected).then(res => {
            //console.log('[Notification]', res);
          }, err => {});
        }
        break;
      // Search devices in native
      case ConnMonState.searchingPaired:
        if (this.pairedDeviceList.length) {
          this.connMonCounter = 0;
          this.connectionMonitorConnect();
        }
        else if (this.connMonCounter > 10) {
          console.log('searchingPaired timeout');
          this.connMonState = ConnMonState.idle;
        }
        break;
      // Connection process
      case ConnMonState.connecting:
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        if (this.isConnected) {
          this.errorCounter = 0;
          this.connMonCounter = 0;
          this.backOff(true);
          this.connMonState = ConnMonState.connected;
        }
        else if (this.connMonCounter > this.exponentialBackoff && this.webBluetoothIdle) {
          console.log('connecting timeout');
          this.connectionAttempts ++;
          this.backOff();
          this.connectionMonitorDisconnect();
        }
        // notification for max connectionAttempts
        if (this.connectionAttempts > 2 && !this.plt.is('capacitor')) { //web
          console.log('max connectionAttempts');
          this.connectionAttempts = 0;
          this.notification.modalControl('timeout').then(res => {
            console.log('[Notification]', res);
          }, err => {});
        } else if (this.connectionAttempts > 3){
          this.connectionAttempts = 0;
          this.notification.modalControl('timeout').then(res => {
            console.log('[Notification]', res);
          }, err => {});
        }
        if (this.desiredConnMonState === ConnMonState.disconnected) {
          this.connectionMonitorDisconnect();
          this.notification.loaderControl('OFF', ConnMonState.disconnected).then(res => {
            console.log('[Notification]', res);
          }, err => {});
        }
        break;
      // Reconnection process - Ainda não é utilizado
      case ConnMonState.reconnecting:
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        if (this.isConnected) {
          this.errorCounter = 0;
          this.connMonCounter = 0;
          this.backOff(true);
          this.connMonState = ConnMonState.connected;
        }
        else if (this.connMonCounter > this.exponentialBackoff) {
          console.log('reconnecting timeout');
          this.backOff();
          this.connectionMonitorDisconnect();
        }
        break;
      // State connected
      case ConnMonState.connected:
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        if (!this.isConnected) {
          // this.connectionMonitorReconnect();
          // this.connMonState = ConnMonState.reconnecting;
          this.connectionMonitorDisconnect();
        }
        if (this.errorCounter > 10) {
          this.successCounter = 0;
          this.connMonCounter = 0;
          this.connMonState = ConnMonState.connectedWithError;
          console.log('BLE_CONNECTED_WITH_ERROR');
          this.bleStatusBS.next('BLE_CONNECTED_WITH_ERROR');
        }
        if (this.desiredConnMonState !== ConnMonState.connected) {
          this.connectionMonitorDisconnect();
        }
        if (this.successCounter > 10) {
          //console.log('[successCounter]', this.successCounter);
          this.successCounter = 0;
          this.errorCounter = 0;
          this.notification.loaderControl('OFF', ConnMonState.connected).then(res => {
            console.log('[Notification]', res);
          }, err => {});
          this.bleStatusBS.next('BLE_CONNECTED_OK');
        }
        break;
      case ConnMonState.connectedWithError:
        if (this.successCounter > 10) {
          this.successCounter = 0;
          this.connMonCounter = 0;
          this.connMonState = ConnMonState.connected;
          this.bleStatusBS.next('BLE_CONNECTED_OK');
        }
        if (this.resetBluetoothLE) {
          this.connectionMonitorDisconnect();
        }
        if (this.errorCounter > 10  || this.connMonCounter > 5) { // Criteria to kill connection
          this.connectionMonitorDisconnect();
        }
        break;
      // Disconnection process
        case ConnMonState.disconnecting:
        if (!this.isConnected) {
          this.connMonState = ConnMonState.disconnected;
        }
        break;
      // State disconnected
      case ConnMonState.disconnected:
        if (this.desiredConnMonState === ConnMonState.connected) {
          this.connMonState = ConnMonState.uninitialized;
        }
        if (this.desiredConnMonState === ConnMonState.disconnected && this.notification.loaderON) {
          // Condição de bloqueio para não ficar entrando na condição
          this.notification.loaderControl('OFF', ConnMonState.disconnected).then(res => {
            console.log('[Notification]', res);
          }, err => {});
        }
        break;
    }
    this.connMonCounter++;
  }


  async connectionMonitorInitialize() {
    this.connMonCounter = 0;
    this.errorCounter = 0;
    this.isConnected = false;
    this.resetBluetoothLE = false;

    this.connMonState = ConnMonState.initializing;

    console.log('connectionMonitorInitialize');

    if (this.plt.is('capacitor')) {
      this.bleInitSub = this.bluetoothLe.initialize({ request: true }).subscribe(async ble => {
        console.log('ble', ble.status); // logs 'enabled'
        if (ble.status === 'enabled') {
          console.log('connectionMonitorInitialize', 'enabled');
          this.bleStatusBS.next('BLE_ENABLED');
          this.checkPermission().then(() => {
            if (this.connMonState === ConnMonState.initializing) { // This may take some time, verify if transition is still relevant
              this.connMonState = ConnMonState.idle;
            }
          }, err => {
            console.log(JSON.stringify(err));
            this.bleStatusBS.next('BLE_PERM_ERROR');

            this.resetBluetoothLE = true;
          });
        } else { // "disabled"
          this.bleStatusBS.next('BLE_DISABLED_ERROR');
          this.disconnect();
          this.notification.modalControl('BLE_disabled').then(res => {
            console.log('[Notification]',res);
            // Caso a pessoa deseje conectar o bluetooth a partir do modal
            if (res === 'enabledBLE') {
              console.log('[TESTE PROMISE]',res);
              this.bluetoothLe.enable();
              // Delay para o sistema ativar o bluetooth
              setTimeout(() => {
                this.connect();
              }, 1000);
            } else {
              this.resetBluetoothLE = true;
            }
          });
        }
      }, err => {
        console.log(JSON.stringify(err));
        this.bleStatusBS.next('BLE_INIT_ERROR');
        this.resetBluetoothLE = true;
      });
    }
    else { // Web
      this.navigator.bluetooth.getAvailability().then(isBluetoothAvailable => {
        console.log(`> Bluetooth is ${isBluetoothAvailable ? 'available' : 'unavailable'}`);
        if (isBluetoothAvailable) {
          this.connMonState = ConnMonState.idle;
        }
      });
    }
  }

  connectionMonitorSearchPaired() {
    this.connMonCounter = 0;
    this.errorCounter = 0;
    this.isConnected = false;
    this.connMonState = ConnMonState.searchingPaired;

    console.log('connectionMonitorSearchPaired');
    const params = {
      services: [
        'C0F1'
      ]
    };
    const maxColibrisPared = 3;

    if (this.plt.is('capacitor')) {
      this.bluetoothLe.retrieveConnected(params).then((res) => {
        const array: any[] = Array(res);
        const deviceList = JSON.parse(JSON.stringify(res, null, '  '));

        // Look for Devices named Colibri (This list should already be filtered by service)
        for(const device of deviceList) {
          console.log('BLE LOOP', device.name, device.address);
          if (device.name.startsWith('Colibri ')) {
            this.pairedDeviceList.push(device);
          }
        }

        if (this.pairedDeviceList.length >= maxColibrisPared) {
          this.notification.modalControl('maxColibrisPared').then(() => {}, err => {});
        } else if (this.pairedDeviceList.length === 0) {
          this.notification.modalControl('anyColibriPared').then(() => {}, err => {});
        }
      }, () => {
        console.log('BLE retrieveConnected ERR');
        this.notification.modalControl('anyColibriPared').then(() => {}, err => {});
      });
    }
    else {
      console.log('connectionMonitorSearchPaired', 'nota available on web');
    }
  }

  async connectionMonitorConnect() {
    this.connMonCounter = 0;
    this.errorCounter = 0;
    this.isConnected = false;
    this.connMonState = ConnMonState.connecting;

    console.log('connectionMonitorConnect');

    this.isConnected = false;
    let colibriDevice;

    if (this.plt.is('capacitor')) {
      try {
        colibriDevice = this.pairedDeviceList.pop();
        this.deviceAddr = colibriDevice.address;
        this.deviceName = colibriDevice.name;
        this.connectionObs = this.bluetoothLe.connect({ address: this.deviceAddr, autoConnect: false }).subscribe(connectResult => {
          if (connectResult.status === 'connected') {
            console.log('Colibri connected!');

            // Gets the device's services
            this.bluetoothLe.discover({ address: this.deviceAddr, }).then(discoverResult => {
              console.log('Discover returned with status: ' + discoverResult.status);

              if (discoverResult.status === 'discovered') {
                // Dispositivo pronto para enviarmos dados!

                console.log('Colibri discovery done!');

                // Subscribe to config read characteristic
                this.bleReadCharacteristicObs = this.bluetoothLe.subscribe({ address: this.deviceAddr,
                  service: this.configServiceUUID, characteristic: this.readCharacteristicUUID });

                // Indica que o colibri está conectado ao receber leituras
                this.readCharacteristicSub = this.bleReadCharacteristicObs.subscribe(async status => {
                  //console.log('bleReadCharacteristicObs', JSON.stringify(status));
                  if (status.value !== undefined) {
                    //console.log('bleReadCharacteristicObs', JSON.stringify(this.bluetoothle.encodedStringToBytes(status.value)));
                    this.notificationHandler(this.bluetoothLe.encodedStringToBytes(status.value));
                  }
                  else {
                    console.log('connectionMonitorConnect', JSON.stringify(status));
                  }
                  this.isConnected = true;
                });

                setTimeout(() => {
                  this.bleStatusBS.next('BLE_DEVICE_READY');
                }, 100);
              }
            });
          }
          else if (connectResult.status === 'disconnected') {
            this.isConnected = false;
            this.resetBluetoothLE = false;
            console.log('Disconnected from device: ' + connectResult.name);
            // this.bleStatusBS.next('BLE_DISCONECTED');
            // this.connectionObs.unsubscribe();
            // return this.connect();
          }
        });
      } catch (error) {
        console.log('multiple devices:',JSON.stringify(error),JSON.stringify(this.pairedDeviceList));
        this.backOff(true);
        if (this.pairedDeviceList.length) {
          this.connectionMonitorConnect();
        }
        else {
          this.notification.modalControl('anyColibriPared').then(() => {}, err => {});
        }
      }
    }
    else { // Web
      if (this.myDevice) {
        // Connect to the device once you find it:
        this.webBluetoothIdle = false;
        this.myDevice.gatt.connect().then((server) => {
          this.myServer = server;
          console.log('gatt.connect', server);
          this.deviceAddr = server.device.name.substring(13);
          // Get the primary service:
          Promise.race([
            this.myServer.getPrimaryService(this.myServiceID),
            new Promise(resolve => setTimeout(resolve, 10000))
          ]).then(async (service) => {
            if (service !== undefined) {
              console.log('Get the primary service OK');
              this.service = service;
              // Get the  characteristic:
              this.service.getCharacteristic(this.writeCharacteristicUUID.toLowerCase()).then((writeCharacteristic) => {
                this.writeCharacteristic = writeCharacteristic;
                console.log('Get the write characteristic OK');
                this.service.getCharacteristic(this.readCharacteristicUUID.toLowerCase()).then(async (readCharacteristic) => {
                  this.readCharacteristic = readCharacteristic;
                  console.log('Get the read characteristic OK');
                  this.readCharacteristic.addEventListener('characteristicvaluechanged', async (event) => {
                    this.notificationHandler(new Uint8Array(event.target.value.buffer));
                    //console.log('notificationHandler');
                  });
                  console.log('addEventListener OK');
                  setTimeout(() => {
                    this.readCharacteristic.startNotifications().then (() => {
                      console.log('startNotifications OK');
                      setTimeout (() => {
                        console.log('Connection Ready');
                        this.webBluetoothIdle = true;
                        this.isConnected = true;
                        this.bleStatusBS.next('BLE_DEVICE_READY');
                      }, 500);
                    }, (err) => {
                      console.log('startNotifications ERROR', err);
                      this.webBluetoothIdle = true;
                    });
                  }, 600);
                }, (err) => {
                  console.log('Get the read characteristic ERROR', err);
                  this.webBluetoothIdle = true;
                });
              }, (err) => {
                console.log('Get the write characteristic ERROR', err);
                this.webBluetoothIdle = true;
              });
            }
            else {
              console.log('Get the primary service TIMEOUT');
              //await this.myDevice.gatt.disconnect();
              this.webBluetoothIdle = true;
            }
          }, (err) => {
            console.log('Get the primary service ERROR', err);
            this.webBluetoothIdle = true;
          });
        }, (err) => {
          console.log('connect ERROR', err);
          this.webBluetoothIdle = true;
        });
      }
    }
  }

  // TODO: Atualmente não está sendo utilizada devido um BUG: https://github.com/randdusing/cordova-plugin-bluetoothle/issues/732
  async connectionMonitorReconnect() {
    this.connMonCounter = 0;
    this.errorCounter = 0;
    this.isConnected = false;
    this.connMonState = ConnMonState.reconnecting;

    console.log('connectionMonitorReconnect');

    // const connected = await this.bluetoothLe.isConnected({address: this.deviceAddr});
    // if (connected.isConnected) {
    //   this.isConnected = true;
    //   console.log('already connected');
    //   return;
    // }

    this.connectionObs = this.bluetoothLe.reconnect({ address: this.deviceAddr}).subscribe(connectResult => {
      if (connectResult.status === 'connected') {
        console.log('Colibri connected!');

        // Gets the device's services
        this.bluetoothLe.discover({ address: this.deviceAddr, }).then(async discoverResult => {
          console.log('Discover returned with status: ' + discoverResult.status);

          if (discoverResult.status === 'discovered') {
            // Dispositivo pronto para enviarmos dados!
            setTimeout(() => {
              this.bleStatusBS.next('BLE_DEVICE_READY');
            }, 1000);
            console.log('connectionMonitorReconnect discovered');
             // Subscribe to config read characteristic
            this.bleReadCharacteristicObs = await this.bluetoothLe.subscribe({ address: this.deviceAddr,
              service: this.configServiceUUID, characteristic: this.readCharacteristicUUID });
              console.log('connectionMonitorReconnect bluetoothLe.subscribe');
              setTimeout (() => {
                this.readCharacteristicSub = this.bleReadCharacteristicObs.subscribe(status => {
                  //console.log('bleReadCharacteristicObs', JSON.stringify(status));
                  if (status.value !== undefined) {
                    //console.log('bleReadCharacteristicObs', JSON.stringify(this.bluetoothle.encodedStringToBytes(status.value)));
                    this.notificationHandler(this.bluetoothLe.encodedStringToBytes(status.value));
                  }
                  else {
                    console.log('connectionMonitorReconnect', JSON.stringify(status));
                  }
                  this.isConnected = true;
                });
              }, 1000);


            //this.isConnected = true;
          }
        });
      }
      else if (connectResult.status === 'disconnected') {
        this.isConnected = false;
        console.log('Disconnected from device: ' + connectResult.name);
        // this.bleStatusBS.next('BLE_DISCONECTED');
        // this.connectionObs.unsubscribe();
        // return this.connect();
      }
    }, (err) => {
      console.log('connectionMonitorReconnect' ,JSON.stringify(err));
      if (err.error === 'isNotDisconnected') {
        this.isConnected = true;
      }
    });
  }

  async connectionMonitorDisconnect() {
    this.connMonCounter = 0;
    this.errorCounter = 0;
    this.isConnected = false;
    this.connMonState = ConnMonState.disconnecting;

    this.bleStatusBS.next('BLE_DISCONNECTING'); // Needed to change the bleStatusBS out of BLE_DEVICE_READY before the modal is loaded;
    console.log('connectionMonitorDisconnect');
    if (this.desiredConnMonState !== ConnMonState.disconnected) {
      // Se o desejado não é desconectar é um processo de restabelecer a conexão
      this.notification.loaderControl('ON', ConnMonState.reconnecting).then(res => {
        console.log('[Notification]', res);
      }, err => {});
    }

    if (this.plt.is('capacitor')) {
    // const connected = await this.bluetoothLe.isConnected({address: this.deviceAddr});
    // if (connected.isConnected) {
      if (this.deviceAddr) {
        console.log('connectionMonitorDisconnect disconnecting');
        await this.bluetoothLe.disconnect({address: this.deviceAddr}).then(() => {
          console.log('connectionMonitorDisconnect disconnected');
        }).catch((err) => {
          console.log('connectionMonitorDisconnect disconnect ERROR', JSON.stringify(err));
        });
        console.log('connectionMonitorDisconnect closing');
        await this.bluetoothLe.close({address: this.deviceAddr}).then(() => {
          this.isConnected = false;
          this.bleStatusBS.next('BLE_DISCONECTED');
          this.readCharacteristicSub.unsubscribe();
          this.connectionObs.unsubscribe();
          this.deviceAddr = undefined;
          console.log('connectionMonitorDisconnect closed GRACEFULLY');
        }, (err) => {
          this.isConnected = false;
          this.bleStatusBS.next('BLE_DISCONECTED');
          this.readCharacteristicSub.unsubscribe();
          this.connectionObs.unsubscribe();
          this.deviceAddr = undefined;
          console.log('connectionMonitorDisconnect close ERROR', JSON.stringify(err));
        });
      }
    }
    else { // Web
      if (this.readCharacteristic) {
        if (this.myDevice.gatt?.connected) {
          //this.readCharacteristic.stopNotifications();
        }
        // this.readCharacteristic.removeEventListener('characteristicvaluechanged', (event) => {
        //   this.notificationHandler(new Uint8Array(event.target.value.buffer));
        // }); //this.handleReadCharacteristicChanged);
        this.readCharacteristic = undefined;
      }
      if (this.myDevice) {
        if (this.myDevice.gatt?.connected) {
          this.myDevice.gatt.disconnect();
        }
      }
    }
    // }
    // else {
    //   console.log('connectionMonitorDisconnect', 'already disconnected');
    //   this.isConnected = false;
    // }
  }



  disconnect() {
    this.desiredConnMonState = ConnMonState.disconnected;
    this.notification.loaderControl('ON', ConnMonState.disconnecting).then(res => {
      console.log('[Notification]', res);
    }, err => {});
  }

  connect() {
    this.desiredConnMonState = ConnMonState.connected;
    if(this.connMonState !== this.desiredConnMonState) {
      this.notification.loaderControl('ON', ConnMonState.connecting).then(res => {
        console.log('[Notification]', res);
      }, err => {});
    }
  }

  writeArrayBuffer(buffer: ArrayBuffer): Promise<any> {
    const writeValue = new Uint8Array(buffer);
    console.log('BLE bluetoothle.write', buffer);

    if (this.isConnected) {
      if (this.plt.is('capacitor')) {
        return this.bluetoothLe.write({ address: this.deviceAddr, service: this.configServiceUUID,
        characteristic: this.writeCharacteristicUUID, value: this.bluetoothLe.bytesToEncodedString(writeValue)})
        .then(res => {
          //console.log('BLE Write OK:', res.status);
          if (res.status === 'written') {
            return Promise.resolve('BLE Write OK');
            //this.sendBleMsgSuccess(transmitObj);
          } else {
            //this.sendBleMsgFail(transmitObj);
            return Promise.reject('BLE Write ERR: res.status != written');
          }
        }, err => {
          console.log('BLE Write ERR:', JSON.stringify(err), this.bluetoothLe.bytesToEncodedString(writeValue));
          //this.sendBleMsgFail(transmitObj);
          //this.reconnect();
          this.errorCounter++;
          return Promise.reject('BLE Write ERR: bluetoothLe.write');
        });
      }
      else { // Web
        if (this.webBluetoothIdle) {
          this.webBluetoothIdle = false;
          return this.writeCharacteristic.writeValueWithResponse(buffer)
          .then((res) => {
            //console.log('BLE Write OK:', res);
            this.webBluetoothIdle = true;
            return Promise.resolve('BLE Write OK');
          }, (err) => {
            console.log('BLE Write ERR:', err);
            this.errorCounter++;
            this.webBluetoothIdle = true;
            return Promise.reject('BLE Write ERR: writeValueWithResponse');
          });
        }
        else{
          console.log('BLE Write BUSY');
          return Promise.reject('BLE Write BUSY');
        }

        // await Promise.any([this.timeout(5000), txPromise])
        // .then((value) => {
        //   if(value === 'timeout') {
        //     console.log('error: x timeout');
        //     this.myDevice.gatt.disconnect();
        //   }
        // });
      }
    }
    else {
      // Uncaught exception from plugin
      // java.lang.NullPointerException: Attempt to invoke virtual method 'boolean java.util.LinkedList.add(java.lang.Object)'
      // on a null object reference

      console.log('BLE Write ERR: disconnected');
      return Promise.reject('BLE Write ERR: disconnected');
    }
  }

  setReadReplyHandler(handler: (msg: Uint8Array) => void) {
    this.notificationHandler = (data) => {
      //console.log('notificationHandler', data);
      handler(data);

      // Esse teste exclui as mensagens do sensor pois elas fazem o contador crescer rápido demais.
      // TODO: É necessário melhorar os critérios de detecção de conexão bem sucedida para que o status 
      // não seja enviado desnecessariamente
      if(data[0] !== ColibriApiMessageType.sensor) {
        this.successCounter++;
      }
    };
  }

  async checkPermission(isScan: boolean = false) {
    // Permission not required on iOS macOS
    if(this.plt.is('capacitor') && !this.plt.is('android')) {
      this.bleStatusBS.next('BLE_PERMISSION_OK');
      return;
    }

    let response;
    // Location is only required for BLE scan
    if (isScan) {
      try {
        console.log('checkPermission', 'isLocationEnabled');
        response = await this.bluetoothLe.isLocationEnabled();
      } catch (error) {
        console.log('hasPermission error: ');
        console.log(error);
        this.bleStatusBS.next('BLE_REQUEST_LOCATION_ERROR');
      }
      if (!response.isLocationEnabled) {
        try {
          response = await this.bluetoothLe.requestLocation();
          if (response.requestLocation) {
            //this.startScan();
            this.bleStatusBS.next('BLE_LOCATION_OK');
          } else {
            this.bleStatusBS.next('BLE_LOCATION_DISABLED_ERROR');
          }
        } catch (error) {
          this.bleStatusBS.next('BLE_REQUEST_LOCATION_ERROR');
        }
      }
    }

    if (parseInt(this.device.sdkVersion, 10) >= 31) {
      // Permissão necessária em Android 12+
      if (isScan) {
        try {
          console.log('checkPermission', 'hasPermissionBtScan');
          response = await this.bluetoothLe.hasPermissionBtScan();
        } catch (error) {
          console.log('hasPermissionBtScan error: ');
          console.log(error);
          this.bleStatusBS.next('BLE_SCAN_HAS_PERMISSION_ERROR');
        }
        if (!response.hasPermission) {
          try {
            response = await this.bluetoothLe.requestPermissionBtScan();
            if (response.requestPermission) {
              //this.startScan();
              this.bleStatusBS.next('BLE_SCAN_REQUEST_PERMISSION_OK');
            } else {
              this.bleStatusBS.next('BLE_SCAN_REQUEST_PERMISSION_DISABLED_ERROR');
            }
          } catch (error) {
            this.bleStatusBS.next('BLE_SCAN_HAS_PERMISSION_DISABLED_ERROR');
          }
        }
      }
      // Permissão necessária em Android 12+
      try {
        console.log('checkPermission', 'hasPermissionBtConnect');
        response = await this.bluetoothLe.hasPermissionBtConnect();
      } catch (error) {
        console.log('hasPermissionBtConnect error: ');
        console.log(error);
        this.bleStatusBS.next('BLE_CONNECT_PERMISSION_DISABLED_ERROR');
      }
      if (!response.hasPermission) {
        try {
          response = await this.bluetoothLe.requestPermissionBtConnect();
          if (response.requestPermission) {
            //this.startScan();
            this.bleStatusBS.next('BLE_CONNECT_PERMISSION_OK');
          } else {
            this.bleStatusBS.next('BLE_CONNECT_PERMISSION_DISABLED_ERROR');
          }
        } catch (error) {
          this.bleStatusBS.next('BLE_CONNECT_PERMISSION_DISABLED_ERROR');
        }
      }
    }
    else {
      try {
        console.log('checkPermission', 'hasPermission');
        response = await this.bluetoothLe.hasPermission();
      } catch (error) {
        console.log('hasPermission error: ');
        console.log(error);
        this.bleStatusBS.next('BLE_HAS_PERMISSION_ERROR');
      }
      if (!response.hasPermission) {
        try {
          response = await this.bluetoothLe.requestPermission();
          if (response.requestPermission) {
            this.bleStatusBS.next('BLE_REQUEST_PERMISSION_OK');
          } else {
            this.bleStatusBS.next('BLE_REQUEST_PERMISSION_DISABLED_ERROR');
          }
        } catch (error) {
          this.bleStatusBS.next('BLE_HAS_PERMISSION_DISABLED_ERROR');
        }
      }
    }
  }

  async hasBle(): Promise<any> {
    if(this.navigator.bluetooth) {
      return this.navigator.bluetooth.getAvailability();
    } else {
      return false;
    }
  }

  /**
   * Reage a eventos de desconexão (espúrios ou solicitados)
   *
   * @param event
   */
  onDisconnected(event) {
    // Object event.target is Bluetooth Device getting disconnected.
    console.log('> Bluetooth Device disconnected', event);
    this.isConnected = false;
  }

  async webSearchDevice() { // Must be directly called from user interaction
    // if (this.myDevice) {
    //   console.log('Device already known');
    //   //delete this.myDevice;
    // } else {
      let firstConnection = false;
      if(!this.myDevice) {
        firstConnection = true;
      }
      this.myDevice = await this.navigator.bluetooth.requestDevice({
        // filters: [myFilters]       // you can't use filters and acceptAllDevices together
        filters: [
          {services: [0xC0F1]},
          {namePrefix: 'Colibri'}
        ],
        optionalServices: [this.myServiceID]
        //acceptAllDevices: false
      });
      if (this.myDevice) {
        if(firstConnection) {
          this.myDevice.addEventListener('gattserverdisconnected', ev => this.onDisconnected(ev));
        }
        return true;
      }
      else {
        return false;
      }
    // }
  }


  // handleReadCharacteristicChanged(event) {
  //   (event) => {this.notificationHandler(new Uint8Array(event.target.value.buffer))};
  // }
}
