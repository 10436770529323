// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../package.json';

export const environment = {
  production: false,
  environmentName: 'dev',
  // environmentName: 'TL',
  version: packageJson.version,
  endOfLifeDate: '2024-01-15',
  googleTagManagerID: 'GTM-M9N852H',
  firebaseConfig: {
    apiKey: 'AIzaSyCZ737TlEd9QKbRNlOyvwW-Ao-jNm8QTsk',
    authDomain: 'colibri-dev1.firebaseapp.com',
    projectId: 'colibri-dev1',
    storageBucket: 'colibri-dev1.appspot.com',
    messagingSenderId: '675863070301',
    appId: '1:675863070301:web:01fa5ae0e540320c52afb4',
    measurementId: 'G-LV7SE8PGRT'
  },
  callableFunctionBaseUrl: 'https://colibri-dev1.web.app/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
