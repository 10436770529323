export enum ColibriApiMessageType {
  //  Operation Modes
  readParam =             0,  // Standard operation mode
  writeParam =            1,  // Mercury switch mode
  commitParam =           2,  // Moves only on the axis with the greatest speed (after speed multiplier)
  reply =                 3,  // Uses a X movement to operate both X and Y
  log =                   4,  // Uses a Y movement to operate both X and Y
  sensor =                5,  // Mode to perform hardware tests
  resetParam =            6,  // Clicks if movement above threshold is detected
  systemReset =           7,  // Performs auto test routine
  keepAlive =             8,  // Disabled
  executeAction =         9,  // Directly execute action (like gestures triggered from API)
  status =                10, // Status report
  mac =                   11, // Get full MAC Addr as string
  serial =                12, // Get / Set serial as a string
  configID =              13  // Get / Set config ID as a string
}

export enum ColibriApiParamID {
  //  Parameter IDs
  apiVersion =            0,  // Version of this API
  fwVersion =             1,  // FW Version Version.SubVersion
  fwRev =                 2,  // FW Sub-Revison Version.Revison
  hwVersion =             3,  // HW Version
  operationMode =         10, // Operation Mode
  hourMeter =             15, // Total usage time in seconds
  xSpeed =                20, // Movement speed in X
  ySpeed =                21, // Movement speed in Y
  scrollSpeed =           22, // Scroll speed (-20 to 20, negative is accelerated)
  buzzerClickLvl =        23, // Buzzer level for click sound
  buzzerAnimationLvl =    24, // Buzzer level for system animations
  rawSensorOutput =       25, // Which types of raw sensor output should be on.
  logLevel =              26, // Log level 0 => disabled; 255 => Verbose;
  xZeroZone =             27, // Zero zone for movement in X
  yZeroZone =             28, // Zero zone for movement in Y
  rollAngle =             29, // Angle deviation from reference for roll gestures
  pitchAngle =            30, // Angle deviation from reference for pitch gestures
  clickSensitivity =      31, // IR sensor sensibility (0 disables the IR sensor as a trigger)
  gestureSpeed =          32, // Gesture yes/no speed level
  gestureIntensity =      33, // Gesture yes/no intensity level
  clickTimeControl =      34, // Time for dwell click or time duration filter
  actionRoll =            35, // Action for roll gestures
  actionNodding =         36, // Action for nodding gestures
  movementTimeControl =   37, // Time to inhibit movement after click start
  actionQuickRoll =       38, // Action for quick roll gestures
  gestureTime =           39, // Time to do quick roll gestures
  noddingGesturesConf =   40, // Settings for Nodding Gestures

  allParams =             255 // Represents all parameters for commits to permanent memory or factory resets
}

export enum ColibriApiAction {
  //  Actions
  clickLeft =             0,  // Mouse LEFT Button
  clickRight =            1,  // Mouse RIGHT Button
  clickLeftDouble =       2,  // Mouse LEFT Button double click
  scrollUp =              3,  // Mouse SCROLL UP
  scrollDown =            4,  // Mouse SCROLL DOWN
  //mouseX =                5,  // Mouse horizontal axis
  //mouseY =                6,  // Mouse vertical axis
  disable =               7,  // Disable mouse actions
  enable =                8,  // Enable mouse actions
  sleep =                 9,  // Enter Sleep Mode
  wakeUp =                10, // Wake UP from Sleep
  dwellClickEnable =      13, // Enable dwell click
  dwellClickDisable =     14, // Disable dwell click
  dwellClickToggle =      15, // Toggle dwell click
  dwellClickTemporary =   16, // Enable hold click for a few seconds
  dwellClickNext =        17, // Toggle dwell click
  holdClickEnable =       18, // Enable hold click
  holdClickDisabled =     19, // Disable hold click
  holdClickTogle =        20, // Toggle hold click
  holdClickTemporary =    21, // Enable hold click for a few seconds
  holdClickNext =         22, // Enable hold click for a few seconds
  dropConnection =        23, // Drops bluetooth connection
  preventSleep5m =        24, // Prevents sleeping for the next 60 seconds
  scrollByNodding =       25, // Enter scroll mode for instant gestures

  doNotChange =           254,// Don't change previous action
  none =                  255 // Enable mouse actions
}

export enum ColibriApiOperationMode {
  //  Operation Modes
  standard =              0,  // Standard operation mode
  hgSwitch =              1,  // Mercury switch mode
  xyExclusive =           2,  // Moves only on the axis with the greatest speed (after speed multiplier)
  singleAxisX =           3,  // Uses a X movement to operate both X and Y
  singleAxisY =           4,  // Uses a Y movement to operate both X and Y
  ablinx =                5,  // Mode to perform hardware tests
  movementDetector =      6,  // Clicks if movement above threshold is detected
  autoTest =              7,  // Performs auto test routine
  disabled =              8   // Disabled
}

export enum ColibriApiReplyCode {
  //  Reply codes
  ok =                    0,  // No error
  invalidType =           1,  // Message type is not recognized
  invalidParam =          2,  // Parameter ID is not recognized
  invalidValue =          3,  // Parameter value invalid or out of range
  clippedValue =          4,  // Parameter was clipped to range
  //systtemBusy           5,  // System is doing something more important than what you've requested
  keepAliveReset =        6,  // Reset issued because keep alive reached zero
}

export enum ColibriApiSensorDataType {
  //  RAW SENSOR DATA FLAVORS for param CONFIG_PARAM_ID_RAW_SENSOR_OUTPUT
  disabled =              0,  // Message type is not recognized
  rpyIrGestVolt =         1,  // Message type is not recognized
}



