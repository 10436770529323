import { StoreService } from './store.service';
import { Injectable } from '@angular/core';
import { User } from 'src/app/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService extends StoreService<User> {
  protected store = 'user';

  constructor() {
    super({
      name: ''
    });
  }
}
