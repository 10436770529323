import { ModalComponent } from './modal/modal.component';
import { LanguageService } from 'src/app/language/language.service';
import { LoadingController, ModalController, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum states {
  uninitialized,
  initializing,
  idle,
  scanning,
  searchingPaired,
  connecting,
  connected,
  reconnecting,
  disconnecting,
  disconnected,
  connectedWithError,
  loading
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  typeOfModal;
  lastState;
  modalResolve;
  alertResolve;
  alertReject;
  loaderON = false;
  alertON = false;
  modalON = false;
  timeInfinite = 289273;
  dismissAuto = false;

  private loader: HTMLIonLoadingElement;
  private alert;
  private modal;

  constructor(public lang: LanguageService,
    private loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController) { }

  /* Central de controle de modais
  * type: determina o tipo de modal que será configurado no component
  */
  modalControl(type): Promise<any> {
    const promise = new Promise<any>(async (resolve, reject) => {
      this.modalResolve = resolve;
    });
    if(this.loaderON){this.dismissLoader();}
    if(this.alertON){this.dismissAlert();}
    this.typeOfModal = type;
    if(!this.modalON){this.openModal();}
    // atualmente não deixa um modal sobrepor o outro
    return promise;
  }

  /* Central de controle de loaders
  * action: Ativa(ON) e desativa(OFF) os loaders
  * state: determina o que será mostrado
  * time: ajusta o tempo de dismiss do loader
  */
  loaderControl(action, state, time = this.timeInfinite): Promise<any> {
    let msg; let loaderResolve; let style;
    const promise = new Promise<any>(async (resolve, reject) => {
      loaderResolve = resolve;
    });
    // Politicas de controle

    if (this.lastState === state) {
      loaderResolve('Repeat command');
      this.lastState = state;
      return promise;
    }
    if (action === 'OFF' && this.dismissAuto) {
      loaderResolve('Dismiss auto');
      this.lastState = state;
      return promise;
    }
    if(this.alertON || this.modalON) {
      loaderResolve('isOccupied');
      this.lastState = state;
      return promise;
    }

    switch (action) {
      case 'ON':
        style = 'bubbles';
        switch (state) {
          case states.connecting: // ativado por ble.connect
            msg = this.lang.words.notifications.loadings.connecting;
            break;
          case states.reconnecting: // TODO: no futuro será chamada de outro lugar, atualmente é chamada no connectionMonitorDisconnect
            msg = this.lang.words.notifications.loadings.reconnecting;
            break;
          case states.disconnecting: // ativado por ble.disconnect
            msg = this.lang.words.notifications.loadings.disconnecting;
            break;
          case states.loading: // ativado pela loading page
            msg = this.lang.words.notifications.loadings.loading;
            break;
          case 'factoryReset':
            msg = this.lang.words.notifications.loadings.factoryReset;
            break;
          case 'successFactoryReset':
            msg = this.lang.words.notifications.loadings.successReset;
            style = null;
            this.dismissAutoLoader(time);
            break;
          case 'failFactoryReset':
            msg = this.lang.words.notifications.loadings.failReset;
            style = null;
            this.dismissAutoLoader(time);
            break;
          case 'saving':
            msg = this.lang.words.notifications.loadings.saving;
            break;
          case 'successCommit':
            msg = this.lang.words.notifications.loadings.successCommit;
            style = null;
            this.dismissAutoLoader(time);
            break;
        }
        //console.log('[LOADER] Show',state);
        if (this.loaderON) { // Caso já tenha um loader na tela
          this.loader.message = msg;
          this.loader.spinner = style;
          loaderResolve('Update message loader');
        } else {
          loaderResolve(this.showLoader(msg, style));
        }

        break;
      case 'OFF': // Ativado pelos estados connected, disconnected e leaveLoading
        loaderResolve(this.dismissLoader());
        return promise;
      default:
        break;
    }
    this.lastState = state;
    return promise;
  }

  /* Central de controle de modais
  * type: determina o tipo de alert que será mostrado
  */
  alertControl(type): Promise<any> {
    //console.log('[Control alert]', this.alertON, this.modalON);
    let header; let subHeader; let msg; let btn = [];
    const promise = new Promise<any>(async (resolve, reject) => {
      this.alertResolve = resolve;
      this.alertReject = reject;
    });

    if(this.loaderON) {this.dismissLoader();}
    if(this.modalON) {this.alertReject('isOccupied');}

    switch (type) {
      case 'errCommit':
        header = this.lang.words.notifications.alerts.alertCommit.title;
        msg = this.lang.words.notifications.alerts.alertCommit.message;
        btn = [this.lang.words.common.ok];
        break;
      case 'errSync':
        header = this.lang.words.notifications.alerts.errorSync.title;
        subHeader = this.lang.words.notifications.alerts.errorSync.subHeader;
        msg = this.lang.words.notifications.alerts.errorSync.message;
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      case 'updateRequired':
        header = this.lang.words.notifications.alerts.updateRequired.title;
        msg = this.lang.words.notifications.alerts.updateRequired.message;
        btn = [this.lang.words.common.ok];
        break;
      case 'saveWizard':
        header = this.lang.words.notifications.alerts.saveWizard.title;
        msg = this.lang.words.notifications.alerts.saveWizard.message;
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      case 'cancelWizard':
        header = this.lang.words.notifications.alerts.cancelWizard.title;
        msg = this.lang.words.notifications.alerts.cancelWizard.message;
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      case 'infoLittleMovement':
        header = this.lang.words.notifications.alerts.infoPersona.little.title;
        msg = this.lang.words.notifications.alerts.infoPersona.little.example;
        btn = [this.lang.words.common.ok];
        break;
      case 'infoStandardMovement':
        header = this.lang.words.notifications.alerts.infoPersona.standard.title;
        msg = this.lang.words.notifications.alerts.infoPersona.standard.example;
        btn = [this.lang.words.common.ok];
        break;
      case 'infoExcessMovement':
        header = this.lang.words.notifications.alerts.infoPersona.excess.title;
        msg = this.lang.words.notifications.alerts.infoPersona.excess.example;
        btn = [this.lang.words.common.ok];
        break;
      case 'infoOther':
        header = this.lang.words.notifications.alerts.infoPersona.other.title;
        msg = this.lang.words.notifications.alerts.infoPersona.other.example;
        btn = [this.lang.words.common.ok];
        break;
      case 'deleteProfile':
        header = this.lang.words.notifications.alerts.deleteProfile.title;
        msg = this.lang.words.notifications.alerts.deleteProfile.message;
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      case 'saveProfile':
        header = 'Deseja salvar as configurações no perfil?';
        msg = 'Deseja salvar as configurações atuais no perfil conectado?';
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      case 'createProfile':
        header = 'Deseja criar um perfil?';
        msg = 'No momento não há nenhum perfil conectado, deseja criar um novo para salvar as configurações atuais?';
        btn = [this.lang.words.common.no,this.lang.words.common.yes];
        break;
      default:
        break;
    }
    //console.log('[Criar alert]', this.alertON, this.modalON);
    if (!this.alertON && !this.modalON) {
      this.createAlert(header, subHeader, msg, btn);
    } else {
      this.alertReject('isOccupied');
    }

    // this.alertCtrl.getTop().then(async res => {
    //   if (!res && this.modalON) {
    //     this.createAlert(header, subHeader, msg, btn);
    //   } else {
    //     this.alertReject('isOccupied');
    //   }
    // });
    return promise;
  }

  async openModal() {
    this.modalON = true;
    setTimeout(async () => {
      this.modal = await this.modalCtrl.create({
        component: ModalComponent,
      });
      this.modal.present();
    }, 500);
  }

  closeModal() {
    this.modalON = false;
    this.modalCtrl.dismiss();
  }

  identifyTypeOfModal() {
    return this.typeOfModal;
  }

  feedbackOfModal(res) {
    this.modalResolve(res);
  }

  private async showLoader(msg, style) {
    //console.log('[Mudei o ON 4]');
    this.loaderON = true;
    this.loader = await this.loadingCtrl.create({
      cssClass: 'custom-loading',
      message: msg,
      spinner: style,
    });
    await this.loader.present();
    return 'Show loader: Ok';
  }

  private async dismissLoader() {
    this.loader.dismiss();
    //console.log('[Mudei o ON 5]');
    this.loaderON = false;
    return 'Dismiss loader: Ok';
  }

  private dismissAutoLoader(time) {
    this.dismissAuto = true;
    setTimeout(() => {
      this.dismissAuto = false;
      this.dismissLoader();
    }, time);
  }

  private async createAlert(title, subTitle, msg, btn = []) {
    this.alertON = true;
    //console.log('[Criar alert]', this.alertON, this.modalON);
    //Truque para conseguir ter alerts com 1 e 2 botões
    if (btn.length === 2){
      this.alert = await this.alertCtrl.create({
        header: title,
        subHeader: subTitle,
        message: msg,
        cssClass:'custom-alert',
        buttons: [
          { text: btn[0], role: 'cancel'},
          { text: btn[1], role: 'confirm'},
        ]
      });
    } else {
      this.alert = await this.alertCtrl.create({
        header: title,
        subHeader: subTitle,
        message: msg,
        cssClass:'custom-alert',
        buttons: [
          { text: btn[0], role: 'cancel'}
        ]
      });
    }
    await this.alert.present();
    const res = await this.alert.onDidDismiss();
    if (res.role === 'cancel') {
      this.alertON = false;
      this.alertReject('cancel');
    } else if (res.role === 'confirm') {
      this.alertON = false;
      this.alertResolve('confirm');
    }
  }

  private dismissAlert() {
    this.alert.dismiss();
    this.alertReject('forcedDismiss');
    this.alertON = false;
  }


}
