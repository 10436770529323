/* eslint-disable no-fallthrough */ // Retira a condição de obrigatoriedade de por break no case
import { ConfigAdvancedPage } from './../config/config-advanced/config-advanced.page';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BleService } from '../ble2/ble2.service';
import { BehaviorSubject } from 'rxjs';
import { IColibriConfig, IOperationMode, IParam, ISerial } from './IColibriConfig';
import { ColibriApiMessageType, ColibriApiParamID, ColibriApiReplyCode, ColibriApiAction, ColibriApiSensorDataType }
from './colibriApiValues.enum';

export interface IRPYSensor {
  roll?: number;
  pitch?: number;
  yaw?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ColibriAPIService {

colibriMAC: BehaviorSubject <string> = new BehaviorSubject <string>('000000000000');
colibriSerial: BehaviorSubject <ISerial> = new BehaviorSubject <ISerial>({serial: '00000000', color: 'x', serialUpdated: false});
colibriRPYData: BehaviorSubject <IRPYSensor> = new BehaviorSubject <IRPYSensor>({roll: 0, pitch: 0, yaw: 0});
msgIdCounter = 0;
replyReceived: ColibriApiReplyCode[] = new Array(255);

paramUpdated: BehaviorSubject <IParam> = new BehaviorSubject <IParam>({id: 0, value: 0});
connectionState: BehaviorSubject <string> = new BehaviorSubject <string>('disconnected');

private connected: false;
private logAccumulator: string;
private debugCommunication = true;

private bleStatusBSSubscription;

private apiVersionNumber = 0;

constructor(private ble: BleService, private platform: Platform) {
  console.log('ColibriAPIService init');
  this.bleStatusBSSubscription = ble.bleStatusBS.subscribe((status) => {
    switch (status){
      case 'BLE_DEVICE_READY':
        this.connectionState.next('connected');
        break;
      case 'BLE_CONNECTED_OK':
        break;
      default:
        this.connectionState.next('disconnected');
    }
  });
  this.ble.setReadReplyHandler( (x)=> {this.handleData(x);} );
}

ngOnDestroy() {
  console.log('ColibriAPIService', 'ngOnDestroy');
  this.bleStatusBSSubscription.unsubscribe();
}

connect() {
  //this.ble.setReadReplyHandler( (x)=> {this.handleData(x);} );
  //return this.ble.openConnectedColibri();
  this.ble.connect();
  //this.ble.showDongleScanningModal();
}

disconnect() {
  this.ble.disconnect();
}

isConnected() {
  return this.connected;
}

apiVersion() {
  return this.apiVersionNumber;
}

/////////////////////////////////////////////////
// API Level Functions - LOW LEVEL
/////////////////////////////////////////////////
/**
 * Saves current configs to permanent memory
 *
 */

getMsgID() {
  this.msgIdCounter++;
  if(this.msgIdCounter > 255) {this.msgIdCounter = 1;}
  //console.log('getMsgID', this.msgIDreference);
  return this.msgIdCounter;
}

commitParam(paramID: ColibriApiParamID){
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const profileSlot = new DataView(msgBuffer, 1, 1);
  const parameterID = new DataView(msgBuffer, 2, 2);
  const msgID = new DataView(msgBuffer, 3, 1);
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.commitParam);
  profileSlot.setUint8(0, 0);
  parameterID.setUint16(0, paramID, true);
  const ID = this.getMsgID();
  msgID.setUint8(0, ID);
  // Send
  return this.ctlDeadline(msgBuffer,ID,7000,100);
}

/**
 * Executa a escrita de um parâmetro
 *
 * @param parameterID ID do parâmetro a ser escrito
 * @param parameterValue Valor do parâmetro unsigned int
 * @returns
 */
writeParamUint(parameterID: ColibriApiParamID, parameterValue: number) {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer,0,1);
  const paramID = new DataView(msgBuffer, 1, 2); //from byte 1 for the next 2 bytes
  const msgID = new DataView(msgBuffer, 3, 1);
  const value = new DataView(msgBuffer, 4, 4);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.writeParam);
  paramID.setUint16(0, parameterID, true);
  msgID.setUint8(0, ID);
  value.setUint32(0, parameterValue, true);
  if(this.debugCommunication) {console.log('writeParamUInt:',msgBuffer);}
  // Send
  return this.ctlDeadline(msgBuffer,ID,7000,100);
}

/**
 * Executa a escrita de um parâmetro
 *
 * @param parameterID ID do parâmetro a ser escrito
 * @param parameterValue Valor do parâmetro int
 * @returns
 */
writeParamInt(parameterID, parameterValue: number) {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const paramID = new DataView(msgBuffer, 1, 2);
  const msgID = new DataView(msgBuffer, 3, 1);
  const value = new DataView(msgBuffer, 4, 4);
  // Fill in Values
  const ID = this.getMsgID();
  msgType.setUint8(0, ColibriApiMessageType.writeParam);
  paramID.setUint16(0, parameterID, true);
  msgID.setUint8(0, ID);
  value.setInt32(0, parameterValue, true);
  if(this.debugCommunication) {console.log('writeParamInt:',msgBuffer);}
  // Send
  return this.ctlDeadline(msgBuffer,ID,7000,100);
}

/**
 * Executa a escrita de um parâmetro
 *
 * @param parameterID ID do parâmetro a ser escrito
 * @param parameterValue Valor do parâmetro int
 * @returns
 */
writeParam4bytes(parameterID, parameterValue0: number, parameterValue1: number, parameterValue2: number, parameterValue3: number) {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const paramID = new DataView(msgBuffer, 1, 2);
  const msgID = new DataView(msgBuffer, 3, 1);
  const value1 = new DataView(msgBuffer, 4, 1); // TODO: conferir se a ordem é essa mesma ou se está invertido
  const value2 = new DataView(msgBuffer, 5, 1);
  const value3 = new DataView(msgBuffer, 6, 1);
  const value4 = new DataView(msgBuffer, 7, 1);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.writeParam);
  paramID.setUint16(0, parameterID, true);
  msgID.setUint8(0, ID);
  value1.setUint8(0, parameterValue0);
  value2.setUint8(0, parameterValue1);
  value3.setUint8(0, parameterValue2);
  value4.setUint8(0, parameterValue3);
  if(this.debugCommunication) {console.log('writeParam4bytes:',msgBuffer);}
  // Send
  return this.ctlDeadline(msgBuffer,ID,7000,100);
}

/**
 * Executa a escrita de um parâmetro
 *
 * @param actionCode ID da ação a ser executada
 *
 * @returns
 */
sendAction(actionCode: ColibriApiAction) {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  const action = new DataView(msgBuffer, 4, 1);
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.executeAction);
  msgID.setUint8(0, this.getMsgID());
  action.setUint8(0, actionCode);

  // Send
  return this.ble.writeArrayBuffer(msgBuffer).catch (err => console.log('ERR: sendAction'));
}

/**
 * Consulta MAC por meio da API
 *
 * @param actionCode ID da ação a ser executada
 *
 * @returns
 */
getMacAddr(apiVersion: number = 0) {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.mac);
  msgID.setUint8(0, ID);
  // Send
  try {
    if (apiVersion >= 13) {
      return this.ctlDeadline(msgBuffer,ID,3000,100);
    } else if(apiVersion >= 11){
      return this.ble.writeArrayBuffer(msgBuffer);
    } else {
      this.getMacAPI10();
      return Promise.resolve('OK getMacAPI10');
    }
  } catch (error) {
    return Promise.reject(error);
  }

}

getMacAPI10(){
  this.colibriMAC.next(this.ble.deviceAddr); // use MAC from name or native library
}

/**
 * Consulta SERIAL por meio da API
 *
 * @returns
 */
getSerial() {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const updateSerial = new DataView(msgBuffer, 1, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.serial);
  updateSerial.setUint8(0, 0x00);
  msgID.setUint8(0, ID);
  console.log('getSerial', msgBuffer);
  // Send
  return this.ctlDeadline(msgBuffer,ID,3000,100);
}

setSerial(serial: string, color: string) {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const updateSerial = new DataView(msgBuffer, 1, 1);
  const colorChar = new DataView(msgBuffer, 2, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  const serialStr = new DataView(msgBuffer, 4, 8);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.serial);
  updateSerial.setUint8(0, 0x01);
  colorChar.setUint8(0, color.charCodeAt(0));
  msgID.setUint8(0, ID);
  for (let i = 0 ; i < 6; i++) {
    serialStr.setUint8(i, serial.charCodeAt(i));
    console.log('setSerial char', serial.charCodeAt(i));
  }
  console.log('setSerial', msgBuffer);
  // Send
  return this.ctlDeadline(msgBuffer,ID,5000,100).catch(err => {
    console.log('ERR: setSerial', err);
  });
}

/**
 * Consulta CONFIG ID por meio da API
 *
 * @returns
 */
async getConfigID() {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const updateID = new DataView(msgBuffer, 1, 1);
  const part = new DataView(msgBuffer, 2, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  let ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.configID);
  updateID.setUint8(0, 0x00);
  msgID.setUint8(0, ID);
  part.setUint8(0, 0x00);
  console.log('getConfigID 0', msgBuffer);
  // Send

  await this.ctlDeadline(msgBuffer,ID,3000,200).catch(err => {
    console.log('ERR: getConfigID 0', err);
  });

  // part 2
  ID = this.getMsgID();
  msgID.setUint8(0, ID);
  part.setUint8(0, 0x01);
  console.log('getConfigID 1', msgBuffer);
  await this.ctlDeadline(msgBuffer,ID,3000,200).catch(err => {
    console.log('ERR: getConfigID 1', err);
  });
}

async setConfigID(id: string) {

  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const updateSerial = new DataView(msgBuffer, 1, 1);
  const part = new DataView(msgBuffer, 2, 1);
  const msgID = new DataView(msgBuffer, 3, 1);
  const serialStr = new DataView(msgBuffer, 4, 16);
  let ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.configID);
  updateSerial.setUint8(0, 0x01);

  msgID.setUint8(0, ID);
  part.setUint8(0, 0x00);
  for (let i = 0 ; i < 16; i++) {
    serialStr.setUint8(i, id.charCodeAt(i));
    console.log('setConfigID char', id.charCodeAt(i));
  }
  console.log('setConfigID 0', msgBuffer);
  // Send
  await this.ctlDeadline(msgBuffer,ID,5000,200).catch(err => {
    console.log('ERR: setConfigID 0', err);
  });

  // part 2
  ID = this.getMsgID();
  msgID.setUint8(0, ID);
  part.setUint8(0, 0x01);
  for (let i = 0 ; i < 16; i++) {
    serialStr.setUint8(i, id.charCodeAt(i+16));
    console.log('setConfigID char', id.charCodeAt(i+16));
  }
  console.log('setConfigID 1', msgBuffer);
  // Send
  await this.ctlDeadline(msgBuffer,ID,5000,200).catch(err => {
    console.log('ERR: setConfigID 1', err);
  });
}

/**
 * Set Sensor RPY por meio da API
 *
 * @returns
 */

setRawSensorOutput(type: ColibriApiSensorDataType) {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const paramID = new DataView(msgBuffer, 1, 2); //from byte 1 for the next 2 bytes
  const msgID = new DataView(msgBuffer, 3, 1);
  const value = new DataView(msgBuffer, 4, 4);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.writeParam);
  paramID.setUint16(0, ColibriApiParamID.rawSensorOutput, true);
  msgID.setUint8(0, ID);
  value.setUint32(0, type, true);
  if(this.debugCommunication) {console.log('setRawSensorOutput:', msgBuffer);}
  // Send
  return this.ctlDeadline(msgBuffer, ID, 1000, 100);
}

/**
 * Solicita a leitura de um parâmetro
 *
 * @param parameterID ID do parâmetro a ser lido
 * @returns
 */
readParam(parameterID, timeoutMS: number = 0): Promise<any> {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const paramID = new DataView(msgBuffer, 1, 2);
  const msgID = new DataView(msgBuffer, 3, 1);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.readParam);
  paramID.setUint16(0, parameterID, true);
  msgID.setUint8(0, ID);
  if(this.debugCommunication) {console.log('readParam',ID,msgBuffer);}
  // Send
  return this.ctlDeadline(msgBuffer,ID,timeoutMS,100);
}

  /**
   *
   * @param timeoutMS Timeout para o Colibri resete caso
   * pare de receber novas mensagens de keep alive
   * @returns
   */
keepAlive(timeoutMS: number) {
    const msgBuffer = new ArrayBuffer(20);
    // Create a couple of views
    const msgType = new DataView(msgBuffer, 0, 1);
    const timeout = new DataView(msgBuffer, 4, 4);
    // Fill in Values
    msgType.setUint8(0, ColibriApiMessageType.keepAlive);
    timeout.setUint32(0, timeoutMS, true);
    // Adiciona à fila de envio e retorna promessa
    return this.ble.writeArrayBuffer(msgBuffer).catch(err => console.log('ERR: keepAlive'));
  }

  /**
   * Reseta todos os par�metros para o default
   */
factoryResetParam(paramID: ColibriApiParamID, timeoutMS: number) {
  const msgBuffer = new ArrayBuffer(20);
  // Create a couple of views
  const msgType = new DataView(msgBuffer, 0, 1);
  const profileSlot = new DataView(msgBuffer, 1, 1);
  const parameterID = new DataView(msgBuffer, 2, 2);
  const msgID = new DataView(msgBuffer, 4, 1);
  const ID = this.getMsgID();
  // Fill in Values
  msgType.setUint8(0, ColibriApiMessageType.resetParam);
  profileSlot.setUint8(0, 0);
  parameterID.setUint16(0, paramID, true);
  msgID.setUint8(0, ID);
  // Send
  return this.ctlDeadline(msgBuffer,ID,timeoutMS,100);
}

readHandler(message: Uint8Array) {
  //const message = new Uint8Array(msg);
  const msgType = new DataView(message.buffer, 0, 1);
  const parameterID = new DataView(message.buffer, 1, 2);
  const value = new DataView(message.buffer, 4, 4);
  const readID = new DataView(message.buffer, 3, 1).getUint8(0);

  if (msgType.getUint8(0) === ColibriApiMessageType.readParam) {
    //console.log('param read received', parameterID.getUint16(0, true), value.getInt32(0, true));
    switch (parameterID.getUint16(0, true)) {
      // All Uint8
      case ColibriApiParamID.apiVersion:
        this.apiVersionNumber = value.getUint8(0);
      case ColibriApiParamID.fwVersion:
      case ColibriApiParamID.fwRev:
      case ColibriApiParamID.hwVersion:
      case ColibriApiParamID.operationMode:
      case ColibriApiParamID.buzzerClickLvl:
      case ColibriApiParamID.buzzerAnimationLvl:
      case ColibriApiParamID.rawSensorOutput:
      case ColibriApiParamID.rollAngle:
      case ColibriApiParamID.pitchAngle:
      case ColibriApiParamID.clickSensitivity:
      case ColibriApiParamID.gestureIntensity:
      case ColibriApiParamID.gestureSpeed:
      case ColibriApiParamID.gestureTime:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value: value.getUint8(0)});
        break;
      // All Uint32
      case ColibriApiParamID.hourMeter:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value:  value.getUint32(0, true)});
        break;
      // All Int8
      case ColibriApiParamID.xSpeed:
      case ColibriApiParamID.ySpeed:
      case ColibriApiParamID.xZeroZone:
      case ColibriApiParamID.yZeroZone:
      case ColibriApiParamID.scrollSpeed:
      case ColibriApiParamID.clickTimeControl:
      case ColibriApiParamID.movementTimeControl:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value: value.getInt8(0)});
        break;
      // Gesture Actions
      case ColibriApiParamID.actionRoll:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value: {
          rollRightActionA: value.getUint8(0),
          rollRightActionB: value.getUint8(1),
          rollLeftActionA: value.getUint8(2),
          rollLeftActionB: value.getUint8(3)
        }});
        break;
      case ColibriApiParamID.actionQuickRoll:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value: {
          quickRollRightActionA: value.getUint8(0),
          quickRollRightActionB: value.getUint8(1),
          quickRollLeftActionA: value.getUint8(2),
          quickRollLeftActionB: value.getUint8(3)
        }});
        break;
      case ColibriApiParamID.actionNodding:
        this.paramUpdated.next({id: parameterID.getUint16(0, true), value: {
          xNoddingActionA: value.getUint8(0),
          xNoddingActionB: value.getUint8(1),
          yNoddingActionA: value.getUint8(2),
          yNoddingActionB: value.getUint8(3)
        }});
        break;
      default:
        console.log('ERROR: read param ID not recognized', parameterID.getUint16(0, true));
        break;
    }
    // Vetor usado pelo ctl para determinar o sucesso da operação
    this.replyReceived[readID] = ColibriApiReplyCode.ok;
    //TODO: Melhoria de print, pois Atualmente estão só printando 0. Não há tempo para receber o dado certo
    //console.log('readReceived:',this.replyReceived[readID],'ID:',readID);
    //console.log('Reply ID', readID);
  }
}

replyMsgHandler(message: Uint8Array) {
  if (message[0] === ColibriApiMessageType.reply) {
    let lastChar = message.slice(4, 20).indexOf(0) + 4;
    if (lastChar < 0) {
      lastChar = 20;
    }

    const text = new Uint8Array(message.slice(4, lastChar));
    // Decodifica valores de ASCII para UTF-8
    const str = new TextDecoder().decode(text);

    const replyType = new DataView(message.buffer, 1, 2);
    const replyMsgID = new DataView(message.buffer, 3, 1).getUint8(0);
    let replyTypeDecoded: string;
    switch(replyType.getUint16(0, true)) {
      case ColibriApiReplyCode.ok:
        replyTypeDecoded = 'OK';
        break;
      case ColibriApiReplyCode.invalidType:
        replyTypeDecoded = 'INVALID TYPE';
        break;
      case ColibriApiReplyCode.invalidParam:
        replyTypeDecoded = 'INVALID PARAM ID';
        break;
      default:
        replyTypeDecoded = 'ERROR_?';
    }
    // Vetor usado pelo ctl para determinar o sucesso da operação
    this.replyReceived[replyMsgID] = replyType.getUint16(0, true) as ColibriApiReplyCode;
    //console.log('replyReceived:',this.replyReceived[replyMsgID],'ID:',replyMsgID);
    //console.log('Reply ID', replyMsgID, ':', replyTypeDecoded, '|', str);
  }
}

statusMsgHandler(message: Uint8Array) {
  //const message = new Uint8Array(msg);
  const msgType = new DataView(message.buffer, 0, 1);
  const status1 = new DataView(message.buffer, 1, 2);
  const value1 = new DataView(message.buffer, 4, 4);
  //console.log('statusMsgHandler', status1.getUint16(0, true));
}

macMsgHandler(message: Uint8Array) {
  //const message = new Uint8Array(msg);
  let mac;
  if (this.apiVersionNumber >= 13) {
    mac = new DataView(message.buffer, 4, 16);
    const replyMsgID = new DataView(message.buffer, 3, 1).getUint8(0);
    this.replyReceived[replyMsgID] = ColibriApiReplyCode.ok;
  } else {
    mac = new DataView(message.buffer, 1, 19);
  }
  // Decodifica valores de ASCII para UTF-8
  const macStr = new TextDecoder().decode(mac);
  this.colibriMAC.next(macStr);
  //console.log('macMsgHandler', macStr);
}

serialMsgHandler(message: Uint8Array) {
  //const message = new Uint8Array(msg);
  const updateSerial = new DataView(message.buffer, 1, 1);
  const color = new DataView(message.buffer, 2, 1);
  const serial = new DataView(message.buffer, 4, 16);
  const replyMsgID = new DataView(message.buffer, 3, 1).getUint8(0);
  this.replyReceived[replyMsgID] = ColibriApiReplyCode.ok;
  // Decodifica valores de ASCII para UTF-8
      // Vetor usado pelo ctl para determinar o sucesso da operação
  const serialStr = new TextDecoder().decode(serial);
  const serialData = {
    serial: serialStr,
    color: new TextDecoder().decode(color),
    serialUpdated: !!updateSerial.getUint8(0)
  } as ISerial;
  this.colibriSerial.next(serialData);
  //console.log('serialMsgHandler', message, serialStr);
}

configIdMsgHandler(message: Uint8Array) {
  //const message = new Uint8Array(msg);
  const updateID = new DataView(message.buffer, 1);
  const configID = new DataView(message.buffer, 4, 16);
  const replyMsgID = new DataView(message.buffer, 3, 1).getUint8(0);
  this.replyReceived[replyMsgID] = ColibriApiReplyCode.ok;
  // Decodifica valores de ASCII para UTF-8
      // Vetor usado pelo ctl para determinar o sucesso da operação
  const configIDStr = new TextDecoder().decode(configID);
  //this.colibriSerial.next(configIDStr);
  //console.log('configIdMsgHandler', message, configIDStr);
}

onLogMessageReceived(message: Uint8Array) {
  if (message[0] === ColibriApiMessageType.log) {
    let lastChar = message.indexOf(0);
    if (lastChar < 0) {
      lastChar = 20;
    }

    const text = new Uint8Array(message.slice(1, lastChar));
    // Decodifica valores de ASCII para UTF-8
    const str = new TextDecoder().decode(text);

    this.logAccumulator += str;

    if (message.indexOf(0) > 0) {
      console.log('Log:', this.logAccumulator);
      this.logAccumulator = '';
    }
  }
}

onSensorMessageReceived(message: Uint8Array) {
  const roll = new DataView(message.buffer, 2, 4).getFloat32(0, true);
  const pitch = new DataView(message.buffer, 6, 4).getFloat32(0, true);
  const yaw = new DataView(message.buffer, 10, 4).getFloat32(0, true);

  const rpyData = {
    roll,
    pitch,
    yaw
  } as IRPYSensor;

  this.colibriRPYData.next(rpyData);
  //console.log('rpyMsgHandler', message, rpyData);
}

// Handle incoming data:
handleData(msg: Uint8Array) {
  //console.log('handleData');
  //const buf = new Uint8Array(msg);

  // Decodificação conforme tipo de mensagem
  switch (msg[0]) {
    case ColibriApiMessageType.reply:
      this.replyMsgHandler(msg);
      break;
    case ColibriApiMessageType.readParam:
      this.readHandler(msg);
      break;
    case ColibriApiMessageType.log:
      this.onLogMessageReceived(msg);
      break;
    case ColibriApiMessageType.sensor:
      this.onSensorMessageReceived(msg);
      break;
    case ColibriApiMessageType.status:
      this.statusMsgHandler(msg);
      break;
    case ColibriApiMessageType.mac:
      this.macMsgHandler(msg);
      break;
    case ColibriApiMessageType.serial:
      this.serialMsgHandler(msg);
      break;
    case ColibriApiMessageType.configID:
      this.configIdMsgHandler(msg);
      break;
    default:
      console.log('Unknown message Type:', msg[0], msg);
  }
}

delay = ms => new Promise(r => setTimeout(r, ms));

ctlDeadline(msg: ArrayBuffer, id: ColibriApiParamID, timeoutMS: number = 0, deltaZero: number){
  if(timeoutMS ===  0){
    return this.ble.writeArrayBuffer(msg);
  } else {
    const deadLine = Date.now() + timeoutMS;
    return this.ctl(msg, id ,deadLine, deltaZero);
  }
}

// Colibri transport layer
async ctl(msg: ArrayBuffer, id: ColibriApiParamID, deadLine: number, deltaZero: number): Promise<any>{
  const timeNow = Date.now();
  const backOff = 2*deltaZero;
  this.replyReceived[id] = undefined; //TODO: o ideal é fazer isso apenas antes da primeira tentativa
  if(this.debugCommunication) {console.log('ID write:',id);}

  return this.ble.writeArrayBuffer(msg).then(async () => { //success BLE send
      await this.delay(250);
      if (this.replyReceived[id] === ColibriApiReplyCode.ok){
        if(this.debugCommunication) {console.log('BLE:',id,'reply OK');}
        return Promise.resolve('REPLY: OK');
      } else {
        if(this.debugCommunication) {console.log('reply ERR:',id);}
        if (timeNow + backOff < deadLine) {
          await this.delay(backOff);
          return this.ctl(msg, id, deadLine, backOff);
        } else {
          if(this.debugCommunication) {console.log('BLE ERR:',id,'no reply');}
          return Promise.reject('ERR: no reply');
        }
      }
    }, async () => { // err send BLE
      if(this.debugCommunication) {console.log('BLE ERR',id);}
      if (timeNow + backOff < deadLine) {
        await this.delay(backOff);
        return this.ctl(msg, id, deadLine, backOff);
      } else {
        if(this.debugCommunication) {console.log('BLE ERR:',id,'timeout');}
        return Promise.reject('ERR: timeout');
      }
    });
  }

}
