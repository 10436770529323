import { UserFirestoreService } from './../database/firestore/user-firestore.service';
import { Injectable } from '@angular/core';
import { User } from './user.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map ,  distinctUntilChanged, tap } from 'rxjs/operators';
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { UserStoreService } from '../database/store/user-store.service';
import { Timestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Documentação da implementação do user:
  // https://github.com/angular/angularfire/blob/57d9005d2e0077d3920e66b5cbcaea1fb7f84450/docs/firestore/collections.md

  constructor(
    private userStore: UserStoreService,
    private userFirestore: UserFirestoreService) {
  }

  get name$() {
    return this.userStore.state$.pipe(map(state => state.name));
  }

  get displayName$() {
    return this.userStore.state$.pipe(map(state => state.displayName));
  }

  get email$() {
    return this.userStore.state$.pipe(map(state => state.email));
  }

  get uid$() {
    return this.userStore.state$.pipe(map(state => state.uid));
  }

  get uid() {
    return this.userStore.state.uid;
  }
  get admin() {
    return this.userStore.state.claims?.admin;
  }
  get analyst() {
    return this.userStore.state.claims?.analyst;
  }
  get support() {
    return this.userStore.state.claims?.support;
  }

  async create(user: User) {
    // Initialize before creation
    this.userStore.clear('clear before creation');

    return this.userFirestore.create(user, user.uid).then(() => {
      // this.userStore.patch({
      //   creationTimestamp: Timestamp.now()
      // }, 'user create SUCCESS');
      // setTimeout(() => this.userStore.patch({ formStatus: '' }, 'employee create timeout reset formStatus'), 2000);
    }).catch(err => {
      // this.userStore.patch({ loading: false, formStatus: 'An error ocurred' }, 'user create ERROR' + err);
      console.error('user create ERROR', err);
    });
  }

  loadUserFromAuth(uid: string) {
    this.userStore.patch({uid}, `user doc auth init`);
    return this.userFirestore.doc$(uid).pipe(
      tap(user => {
        this.userStore.patch(user, `user doc subscription`);
      })
    );
  }

  async clear(event: string = undefined) {
    // Initialize before creaation
    this.userStore.clear(event);
  }

  setName(value: string, commit: boolean = false) {
    this.userStore.patch({
      name: value
      }, 'user set name');
    if (commit) {
      this.userFirestore.update({name: value}, this.userStore.state.uid);
    } else {
      return Promise.resolve();
    }
  }

  setEmail(value: string, commit: boolean = false) {
    this.userStore.patch({
      name: value
      }, 'user set email');
    if (commit) {
      return this.userFirestore.update({email: value}, this.userStore.state.uid);
    } else {
      return Promise.resolve();
    }
  }

  setClaims(claims: any, commit: boolean = false) {
    this.userStore.patch({
      claims
      }, 'user set claims');
    if (commit) {
      //return this.userFirestore.update({email: value}, this.userStore.state.uid);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Sends all user data (avoid usage, since  it may overwrite)
   */
  commit() {
    return this.userFirestore.update(this.userStore.state, this.userStore.state.uid);
  }

  // initWithAuth(uid: string) {
  //   this.userDoc = this.afs.doc<User>('users/' + uid);
  // }

  // private update(user: User) {
  //   return this.userDoc.update(user);
  // }

}
