import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguageService } from 'src/app/language/language.service';


@Component({
  selector: 'app-create-new-profile',
  templateUrl: './create-new-profile.component.html',
  styleUrls: ['./create-new-profile.component.scss'],
})
export class CreateNewProfileComponent implements OnInit {

  typeOfPerfil;

  name: string;

  constructor(private modal: ModalController,
    public lang: LanguageService) {}

  ngOnInit() {}

  selectedTypeOfProfile(type) {
    this.typeOfPerfil = type;
    //console.log('[PROFILE NAME]',type);
  }

  saveBtnClicked() {
    //console.log('[PROFILE DATA]', this.typeOfPerfil, this.name);
    const data = {
      name: this.name,
      type: this.typeOfPerfil
    };
    this.modal.dismiss(data);
  }
}
