import { ISession } from 'src/app/session/session.model';
import { FirestoreService } from './firestore.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionFirestoreService extends FirestoreService<ISession>{

  protected basePath = 'sessions';
}
