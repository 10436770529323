import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelButtonComponent } from '../cancel-button/cancel-button.component';
import { IonicModule } from '@ionic/angular';
import { CreateNewProfileComponent } from '../create-new-profile/create-new-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [CancelButtonComponent, CreateNewProfileComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CancelButtonComponent, CreateNewProfileComponent]

})
export class ControlButtonsModule { }
