import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ControlButtonsModule } from '../components/control-buttons/control-buttons.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    ControlButtonsModule
  ]
})
export class SessionModule { }
