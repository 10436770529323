// import { ServiceWorkerModule } from '@angular/service-worker';
// import { ServiceWorkerModule } from '@angular/service-ServiceWorkerModule';
import { SessionModule } from './session/session.module';
import { NotificationModule } from './notifications/notification.module';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { BleModule } from './ble2/ble2.module';
import { Device } from '@awesome-cordova-plugins/device/ngx';

// Firebase services + environment module
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { AuthModule } from './auth/auth.module';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';


@NgModule({
    declarations: [AppComponent],
    imports: [
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFirestoreModule,
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFunctions(() => getFunctions()),
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
      BrowserModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      HttpClientModule,
      BleModule,
      NotificationModule,
      SessionModule,
      AuthModule,
      // ServiceWorkerModule.register('ngsw-worker.js', {
      //   enabled: !isDevMode(),
      //   // Register the ServiceWorker as soon as the application is stable
      //   // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:5000'
      // })
    ],
    providers: [
        // AuthService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {provide: 'googleTagManagerId', useValue: environment.googleTagManagerID},
        BluetoothLE,
        Device,
        Dialogs,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
