import { ISession } from 'src/app/session/session.model';
import { StoreService } from './store.service';
import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class SessionStoreService extends StoreService<ISession> {
  protected store = 'session';

  constructor() {
    super({}); // Inicializar os valores
  }
}
