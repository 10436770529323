import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/user/user.service';
import { AuthService } from '../auth.service';
import { AuthIconBtnPopoverComponent } from './auth-icon-btn-popover/auth-icon-btn-popover.component';

@Component({
  selector: 'app-auth-icon-button',
  templateUrl: './auth-icon-button.component.html',
  styleUrls: ['./auth-icon-button.component.scss'],
})
export class AuthIconButtonComponent implements OnInit, OnDestroy {

  authUserSub: Subscription;
  userSub: Subscription;
  isLoggedIn = false;
  userProfilePhotoSrc = '';
  userProfilePhotoName = '';
  mediasDownloadProgressSub: Subscription;
  mediasDownloadProgress = { isDownloading: false, processed: 0, total: 0, percentage: 0 };

  constructor(protected authService: AuthService, private popoverController: PopoverController,
              public userService: UserService) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if (this.authUserSub) { this.authUserSub.unsubscribe(); }
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.mediasDownloadProgressSub) { this.mediasDownloadProgressSub.unsubscribe(); }
  }

  async onAuthIconBtnClicked(ev: any) {
    const popover = await this.popoverController.create({
      component: AuthIconBtnPopoverComponent,
      event: ev,
      translucent: true,
      cssClass: 'app-popover-lg'
    });
    return await popover.present();
  }

}
