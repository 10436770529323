import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, QueryFn, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Timestamp } from '@angular/fire/firestore';

@Injectable()
export abstract class FirestoreService<T> {

  protected abstract basePath: string;

  constructor(
      @Inject(AngularFirestore) protected firestore: AngularFirestore,
  ) {

  }

  private get collection() {
    return this.firestore.collection(`${this.basePath}`);
  }

  doc$(id: string): Observable<T> {
    return this.firestore.doc<T>(`${this.basePath}/${id}`).valueChanges().pipe(
      tap(r => {
        if (!environment.production) {
          console.groupCollapsed(`Firestore Streaming [${this.basePath}] [doc$] ${id}`);
          console.log(r);
          console.groupEnd();
        }
      }),
    );
  }

  collection$(queryFn?: QueryFn): Observable<T[]> {
    //console.log('[firestore][collection$]', this.basePath);
    return this.firestore.collection<T>(`${this.basePath}`, queryFn).valueChanges().pipe(
      tap(r => {
        if (!environment.production) {
          console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collection$]`);
          console.table(r);
          console.groupEnd();
        }
      }),
    );
  }

  collectionSnapshot$(queryFn?: QueryFn): Observable<{id: string, data: T}[]> {
    //console.log('[firestore][collection$]', this.basePath);
    return this.firestore.collection<T>(`${this.basePath}`, queryFn).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as T;
        const id = a.payload.doc.id;
        return { id: id, data: data };
      })),
      tap(r => {
        if (!environment.production) {
          console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collectionSnapshot$]`);
          r.forEach((snapshot)=>{
            console.log('id', snapshot.id);
            console.log('data', snapshot.data);
          })
          console.groupEnd();
        }
      }),
    );
  }

  // Pega apenas um snapshot
  // EX:
  // this.profile.collectionGet().pipe(first()).subscribe((snapshots) => {
  //   snapshots.docs.forEach((doc) => {
  //     console.log(doc.data())
  //   })
  // });

  collectionGet(queryFn?: QueryFn): Observable<QuerySnapshot<T>> {
    console.log('[firestore][collectionGet]', this.basePath);
    return this.firestore.collection<T>(`${this.basePath}`, queryFn).get().pipe(
      tap(r => {
        if (!environment.production) {
          console.groupCollapsed(`Firestore Query [${this.basePath}] [collectionGet]`);
          r.docs.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
          });
          console.groupEnd();
        }
      }),
    );
  }

  async create(value: T, docID: string = undefined) {
    let id: string;
    if (docID) {
      id = docID; // Use provided doc ID
    } else {
      id = this.firestore.createId(); // Generate random doc ID
    }

    const creationTimestamp = Timestamp.now(); // Create timestamp if none is specified

    return this.collection.doc(id).set(Object.assign({}, { creationTimestamp }, value)).then(() => {
      if (!environment.production) {
        console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`);
        console.log('[Id]', id, value);
        console.groupEnd();
      }
      return id;
    });
  }

  async updateTimestamp(value: Partial<T>, docID: string) {

    const updateTimestamp = Timestamp.now(); // Register last update time
    // set with merge will update fields in the document or create it if it doesn't exists
    return this.collection.doc(docID).set(Object.assign({}, value, { updateTimestamp }),{merge: true}).then(_ => {
      if (!environment.production) {
        console.groupCollapsed(`Firestore Service [${this.basePath}] [update]`);
        console.log('[Id]', docID, value);
        console.groupEnd();
      }
    });
  }

  async update(value: Partial<T>, docID: string) {

    return this.collection.doc(docID).set(Object.assign({}, value),{merge: true}).then(_ => {
      if (!environment.production) {
        console.groupCollapsed(`Firestore Service [${this.basePath}] [update]`);
        console.log('[Id]', docID, value);
        console.groupEnd();
      }
    });
  }

  async delete(id: string) {
    return this.collection.doc(id).delete().then(_ => {
      if (!environment.production) {
        console.groupCollapsed(`Firestore Service [${this.basePath}] [delete]`);
        console.log('[Id]', id);
        console.groupEnd();
      }
    });
  }
}
