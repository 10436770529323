import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { IProfile } from 'src/app/session/session.model';

@Injectable({
  providedIn: 'root'
})

export class ProfileFirestoreService extends FirestoreService<IProfile> {
  public basePath = '';
}
