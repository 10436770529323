import { IColibriConfig } from './../colibriHAL/IColibriConfig';
import { inject, Injectable } from '@angular/core';
import { Functions, HttpsCallable, httpsCallable } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { UsageLog } from '../admin/logs/usageLog.model';
import { UserService } from '../user/user.service';
import { AuthService } from '../auth/auth.service';
import { httpsCallableFromURL } from 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private functions: Functions = inject(Functions);

  private colibriHwInfoV1Callable: HttpsCallable;

  constructor(private userService: UserService, private auth: AuthService) {
    this.colibriHwInfoV1Callable = httpsCallableFromURL(this.functions, environment.callableFunctionBaseUrl + 'hwInfoV1');
  }

  logColibriEvent(mac: string, serial: string, color: string, oldConfig: IColibriConfig = undefined, savedConfig: IColibriConfig = undefined) {
    const event: UsageLog = {
      serial,
      color,
      mac,
      uid: this.userService.uid,
      weakUid: this.auth.lastUserUid,
      oldConfig,
      savedConfig
    };
    if (!environment.production) {
      console.groupCollapsed('Log ColibriEvent');
      console.log(event);
      console.groupEnd();
    }
    return this.colibriHwInfoV1Callable(event);
  }
}
