import { UserService } from './../user/user.service';
import { inject, Injectable, isDevMode } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword,
  signInWithPopup, User, user, UserCredential, AuthProvider, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { updateProfile } from 'firebase/auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  //isLoggedIn = false;

  pendingEmail = '';
  pendingPwd = '';

  authUserSub: Subscription;
  userSub: Subscription;
  lastUserUid: string;

  private auth: Auth = inject(Auth);
  private user$ = user(this.auth);

  private userAuthData: User; // Save logged in user data

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.authUserSub = this.user$.subscribe((aUser: User | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
      if (aUser) {
        if (this.userSub) {
          this.userSub.unsubscribe();
        }
        this.userSub = this.userService.loadUserFromAuth(aUser.uid).subscribe();
        this.userAuthData = aUser;
        this.lastUserUid = aUser.uid;
        this.fetchPrivileges();
        if(isDevMode()) {
          console.log('auth: logado', this.userAuthData.uid);
        }
      } else {
        this.userSub?.unsubscribe();
        this.userAuthData = undefined;
        if(isDevMode()) {
          console.log('auth: logout', this.lastUserUid);
        }
      }
    });
  }

  // Returns true when user is logged
  get isLoggedIn(): boolean {
    return this.userAuthData !== undefined;
  }

  // Returns true when user is logged in and email is verified
  get isLoggedInVerified(): boolean {
    return (this.userAuthData !== undefined) && (this.userAuthData?.emailVerified);
  }

  // DOC: https://firebase.google.com/docs/auth/admin/custom-claims
  async fetchPrivileges() {
    if (this.isLoggedIn) {
      await this.auth.currentUser.getIdToken();
      await this.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        // Confirm the user is an Admin.
        if (idTokenResult.claims) {
          this.userService.setClaims(idTokenResult.claims);
        }
      });
    }
  }

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.authUserSub.unsubscribe();
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  // Sign in with email/password
  async signIn(email: string, password: string) {
    try {
      const result = await signInWithEmailAndPassword(this.auth, email, password);
      this.userAuthData = result.user;
      this.lastUserUid = this.userAuthData.uid;
      // this.router.navigate(['/home']);
      this.router.navigate(['/profile-choice']);

      // this.userService.loadUserFromAuth(result.user.uid);
      // this.afAuth.authState.subscribe((user1) => {
      //   if (user1) {
      //     this.router.navigate(['profile']);
      //   }
      // });
    } catch (error) {
      window.alert(error.message);
    }
  }

  // Sign up with email/password
  async signUp(email: string, password: string, displayName: string) {
    let result: UserCredential;
    try {
      result = await createUserWithEmailAndPassword(this.auth, email, password);
      this.userAuthData = result.user;
      this.lastUserUid = this.userAuthData.uid;

      console.log('result', this.lastUserUid);

      await this.sendVerificationMail();

      await this.userService.create({
        uid: result.user.uid,
        displayName,
        email,
      });
      // result.user.updateProfile({displayName});
      updateProfile(this.userAuthData, {displayName});

    } catch (error) {
      if (result) {
        await result.user.delete();
      }
      this.router.navigate(['sign-up']);
      window.alert(error.message);
    }
  }

  // Send email verfificaiton when new user sign up
  async sendVerificationMail() {

    const actionCodeSettings = {
      url: 'https://mycolibri.app/?email=' + this.userAuthData.email,
      iOS: {
        bundleId: 'app.colibri',
        minimumVersion: '23'
      },
      android: {
        packageName: 'app.colibri',
        installApp: true,
        minimumVersion: '23'
      },
      handleCodeInApp: true,
      // When multiple custom dynamic link domains are defined, specify which
      // one to use.
      //dynamicLinkDomain: environment.dynamicLinkDomain
    };


    return sendEmailVerification(this.userAuthData, actionCodeSettings)
    .then(() => {
      this.router.navigate(['verify-email']);
    });
    // return this.afAuth.currentUser
    //   .then((u: any) => u.sendEmailVerification())
    //   .then(() => {
    //     this.router.navigate(['verify-email']);
    //   });
  }

  // Reset Forggot password
  async forgotPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail)
    .then(() => {
      this.router.navigate(['forgot-password']);
    })
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    })
    .catch((error) => {
      window.alert(error);
    });
    // return this.afAuth
    //   .sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     this.router.navigate(['forgot-password']);
    //   })
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   })
    //   .catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Auth logic to run auth providers
  async authLogin(provider: AuthProvider) {
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        this.router.navigate(['profile']);
        //this.setUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
    // return this.afAuth
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     this.router.navigate(['profile']);
    //     //this.setUserData(result.user);
    //   })
    //   .catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Sign out
  async signOut() {
    return signOut(this.auth)
      .then(() => {
        // localStorage.removeItem('user');
        this.userService.clear();
        this.router.navigate(['home']);
      });
    // return this.afAuth.signOut().then(() => {
    //   // localStorage.removeItem('user');
    //   this.userService.clear();
    //   this.router.navigate(['home']);
    // });
  }

  emailVerified() {
    return this.userAuthData?.emailVerified;
  }
}
