import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BleService } from '../ble2.service';
import { LanguageService } from 'src/app/language/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ble-scanning',
  templateUrl: './ble-scanning.component.html',
  styleUrls: ['./ble-scanning.component.scss'],
})
export class BleScanningComponent implements OnInit {

  bleStatus = '';
  bleError = false;
  errorMsg = '';
  bleStatusSubscription;
  reconnectionInterval;

  constructor(private bleService: BleService, private modalController: ModalController, public lang: LanguageService,
    private router: Router, private navCtrl: NavController) { }

  ngOnInit() {
    console.log('BleScanningComponent', 'init');
    this.bleStatusSubscription = this.bleService.bleStatusObs.subscribe(status => {
      console.log('bleStatusObs', status);
      this.bleStatus = status;
      switch(status) {
        case 'BLE_INIT':
          //this.scanForHidDongle();
          break;
        case 'BLE_DEVICE_READY':
        case 'BLE_CONNECTED_OK':
          this.modalController.dismiss();
          break;
        case 'BLE_DISCONECTED':
          this.bleError = true;
          this.errorMsg = this.lang.words.ble.colibriDisconnected;
          break;
        default:
          this.bleError = true;
          this.errorMsg = this.lang.words.ble.bluetoothPermission;
          break;
      }

      // if (status === 'BLE_INIT') {
      //   //this.scanForHidDongle();
      // } else if (status === 'BLE_DEVICE_READY') {
      //   this.modalController.dismiss();
      // } else if (status === 'BLE_DISCONECTED') {
      //   this.bleError = true;
      //   this.errorMsg = this.lang.words.ble.colibriDisconnected;
      // } else if (status.includes('ERROR')) {
      //   this.bleError = true;
      //   this.errorMsg = this.lang.words.ble.bluetoothPermission;
      // }
    });

    this.scanForHidDongle();
    this.reconnectionInterval = setInterval(() => {this.scanForHidDongle();}, 5000);

  }

  ngOnDestroy() {
    clearInterval(this.reconnectionInterval);
    this.bleStatusSubscription.unsubscribe();
  }

  scanForHidDongle() {
    this.bleError = false;
    //this.bleService.connect();
    setTimeout(() => {
      if (!this.bleError && this.bleStatus !== 'BLE_DEVICE_READY') {
        this.errorMsg = this.lang.words.ble.connectedColibriNotFound;
        this.bleError = true;
        //this.bleService.stopScan();
      }
    }, 5000);
  }

  onBackBtnClicked() {
    console.log('onBackBtnClicked');
    this.bleService.disconnect();
    this.modalController.dismiss('pace-not-set');
    this.navCtrl.navigateBack('/home');
  }

  onTryAgainBtnClicked() {
    //this.scanForHidDongle();
    this.bleService.disconnect();
    setTimeout(() => {
      this.modalController.getTop().then(async modal => {
        if (modal) {
          this.modalController.dismiss();
        }
      });
      location.reload();
      //this.router.navigate(['']);
    }, 500);
  }
}
