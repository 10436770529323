import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/session/session.service';
import { NotificationService } from 'src/app/notifications/notification.service';
import { NavController } from '@ionic/angular';


@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent implements OnInit {

  constructor( public session: SessionService,
    private notification: NotificationService,
    private navCtrl: NavController,
    ) { }

  ngOnInit() {}

  onCancelBtnClicked(ev: any) {
    this.notification.alertControl('cancelWizard').then(res => {
      if (res === 'confirm') {
        this.session.sessionStore.state.wizard.feedback = false;
        this.session.syncWriteQ.next('Feedback');
        this.session.revertConfig();
        this.navCtrl.navigateForward('/home');
      }
    }, err => {});
  }

}
