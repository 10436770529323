import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { User } from 'src/app/user/user.model';

@Injectable({
    providedIn: 'root'
})

export class UserFirestoreService extends FirestoreService<User> {
  protected basePath = 'users';
}
