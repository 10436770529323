import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthIconButtonComponent } from './auth-icon-button/auth-icon-button.component';
import { AuthIconBtnPopoverComponent } from './auth-icon-button/auth-icon-btn-popover/auth-icon-btn-popover.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [
    AuthIconButtonComponent,
    AuthIconBtnPopoverComponent
  ],
  entryComponents: [
    AuthIconBtnPopoverComponent
  ],
  exports: [
    AuthIconButtonComponent
  ]
})
export class AuthModule { }
